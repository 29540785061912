import React, { useEffect, useState, useRef, } from 'react';
import { createChart } from 'lightweight-charts';
import { Box, Autocomplete, TextField, useTheme } from "@mui/material";

const Closenbroker = () => {
  const theme = useTheme();
  const [crosshairDataPoint, setCrosshairDataPoint] = useState(null);
  const [selectedBroker, setSelectedBroker] = useState(null);
  const [symbols, setSymbols] = useState([]);
  const chartContainerRef1 = useRef(null);
  const chartContainerRef2 = useRef(null);

  const fetchDataForCharts = () => {
    if (!selectedBroker) return;
    fetch(`https://ideatrade1.com:4002/brokerranking_chart?broker_name=${encodeURIComponent(selectedBroker)}`)
      .then(response => response.json())
      .then(data => {
        // Remove the previous chart elements
        const chartContainer1 = chartContainerRef1.current;
        while (chartContainer1.firstChild) {
          chartContainer1.removeChild(chartContainer1.firstChild);
        }

        const chartContainer2 = chartContainerRef2.current;
        while (chartContainer2.firstChild) {
          chartContainer2.removeChild(chartContainer2.firstChild);
        }

        // Split the data into two parts
        //const data1 = data.broker_data;
        //const data2 = data.stock_data;

        const data1 = data.data.stock_data;
        const data2 = data.data.broker_data;

        console.log("This first chart data: ",data.data.stock_data)

        // Initialize chart 1
        const chart1 = createChart(chartContainerRef1.current, {
          width: chartContainerRef1.current.clientWidth,
          height: chartContainerRef1.current.clientHeight,
          crosshair: {
            mode: 0
          }
        });
        chartContainerRef1.current.chartInstance = chart1;

        chart1.applyOptions({
            watermark:{
              visible: true,
              fontSize: 24,
              horzAlign: 'left',
              vertAlign: 'top',
              color: 'rgb(0,0,0)',
              text: 'Set',
            }
           })

        const lineSeries1 = chart1.addLineSeries({ color: 'red' });
        lineSeries1.setData(data1);

        // Initialize chart 2
        const chart2 = createChart(chartContainerRef2.current, {
          width: chartContainerRef2.current.clientWidth,
          height: chartContainerRef2.current.clientHeight,
          crosshair: {
            mode: 0
          }
        });
        chartContainerRef2.current.chartInstance = chart2;

        chart2.applyOptions({
            watermark:{
              visible: true,
              fontSize: 24,
              horzAlign: 'left',
              vertAlign: 'top',
              color: 'rgb(0,0,0)',
              text: selectedBroker,
            }
           })

        const lineSeries2 = chart2.addLineSeries({ color: 'green' });
        lineSeries2.setData(data2);

        // Set up crosshair synchronization and range change subscription
        subscribeCrosshairMove(chart1, chart2, lineSeries1, lineSeries2);
        subscribeCrosshairMove(chart2, chart1, lineSeries2, lineSeries1);
        subscribeVisibleLogicalRangeChange(chart1, chart2);
        subscribeVisibleLogicalRangeChange(chart2, chart1);
      })
      .catch(error => console.error('Error fetching data for charts:', error));
  };

  useEffect(() => {
    fetchDataForCharts();
  }, [selectedBroker]);

  useEffect(() => {
    fetch('https://ideatrade1.com:4002/brokerranking_symbols')
      .then(response => response.json())
      .then(data => setSymbols(data.data.map(broker => broker.label)))
      .catch(error => console.error('Error fetching symbols:', error));
  }, []);

  const handleCrosshairMove = (param, sourceSeries, targetChart, targetSeries) => {
    const dataPoint = getCrosshairDataPoint(sourceSeries, param);
    syncCrosshair(targetChart, targetSeries, dataPoint);
    setCrosshairDataPoint(dataPoint);
  };

  const syncCrosshair = (chart, series, dataPoint) => {
    if (dataPoint) {
      chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
    } else {
      // chart.clearCrosshairPosition();
    }
  };

  const getCrosshairDataPoint = (series, param) => {
    if (!param.time || !param.seriesData) {
      return null;
    }
    return param.seriesData.get(series) || null;
  };

  const subscribeCrosshairMove = (sourceChart, targetChart, sourceSeries, targetSeries) => {
    sourceChart.subscribeCrosshairMove((param) => {
      handleCrosshairMove(param, sourceSeries, targetChart, targetSeries);
    });
  };

  const subscribeVisibleLogicalRangeChange = (sourceChart, targetChart) => {
    sourceChart.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
      targetChart.timeScale().setVisibleLogicalRange(timeRange);
    });
  };


  return (
    <Box sx={{
      position: 'absolute',
      margin: 0,
      padding: 0,
      top: 0,
      left: '0vw',
      backgroundColor: '#fff',
      width: '100%',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      zIndex: '90000'

    }}>
  
      {/* Second chart with input and button */}
      <div style={{ width: '50%', float: 'right' }} >
        <Box sx={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          //bgcolor: 'yellow',
          left: 0,
          top: '2vh',
          zIndex: '100000'
        }}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={symbols}
            value={selectedBroker}
            onChange={(event, newValue) => {
              setSelectedBroker(newValue);
            }}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Symbols" />}
          />
          
        </Box>
       
        <Box sx={{
          position: 'absolute',
          width: {

            [theme.breakpoints.up('lg', '1400')]: {
                width: '100%',
             
            },//Higher screen

            [theme.breakpoints.between('lg', '1370')]: {
                width: '100%',
             
            },//IPadProHorizontal
            [theme.breakpoints.between('md', '1270')]: {
                width: '100%',
             
            },//macbook2020
            [theme.breakpoints.between('lg', '1370')]: {
                width: '100%',
             
            },//IPadProHorizontal
            [theme.breakpoints.between('md', '1190')]: {
                width: '100%',
             
            },//IPadAirHorizontal
            [theme.breakpoints.between('md', '1030')]: {
                width: '85%',
              
            },//IPadPro
            [theme.breakpoints.between('sm', '830')]: {
                width: '85%',
             
            },//IPadAir
            [theme.breakpoints.between('sm', '770')]: {
              width: '85%',
              
            },//IPadMini
          },
          height: '90vh',
         // backgroundColor: 'greenyellow',
          bottom: '0vh',
          right: '0vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
         //justifyContent: 'center',
        }}>
          <div ref={chartContainerRef1} style={{ width: '87%', height: '45%',}} >
        
          </div>
          <br />
          <div ref={chartContainerRef2} style={{ width: '87%', height: '45%' }} >
         
          </div>
        </Box>
      </div>
    </Box>
  );
};

export default Closenbroker;

