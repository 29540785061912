import React from 'react';
import { Typography, Button, Container } from '@mui/material';

const Error403Page = () => {
    return (
        <Container
            maxWidth="sm"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                flexDirection: 'column',
                textAlign: 'center',
            }}
        >
            <Typography variant="h1" gutterBottom>
                403
            </Typography>
            <Typography variant="h5" gutterBottom>
                Access Denied
            </Typography>
            <Typography variant="body1" gutterBottom>
                You do not have permission to access this page.
            </Typography>
            <Button variant="contained" color="primary" href="/">
                Go Home
            </Button>
        </Container>
    );
};

export default Error403Page;
