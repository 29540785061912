import { useEffect, useState, useRef, useMemo } from 'react';
import { tokens } from "../../theme";
import Header from "../../components/Header";
import {
    useTheme, Box, Container, Grid, Card, TextField, Button, Table, TableHead, TableRow, TableCell, TableBody, Typography, Paper, Autocomplete,
    ToggleButton, ToggleButtonGroup, Popper, CircularProgress, MenuItem
} from '@mui/material';

import { createChart, CrosshairMode, PriceScaleMode } from 'lightweight-charts';

import { __fetching_data__ } from '../../components/api';
import { date } from 'yup';


const compareDatetime = (a, b, field = 'DateTime') => {
    const datetimeA = new Date(a[field]);
    const datetimeB = new Date(b[field]);
    return datetimeA - datetimeB;
}
const filterByYears = (data, years) => {
    if (years === 'ALL') {
        return data; // Return all data when 'ALL' is specified
    }
    const currentDate = new Date();
    const filteredData = data.filter(item => {
        if (item.hasOwnProperty('Date')) {
            const itemDate = new Date(item.Date);
            const differenceInYears = (currentDate - itemDate) / (365 * 24 * 60 * 60 * 1000);
            return differenceInYears <= years;
        }
        if (item.hasOwnProperty('date')) {
            const itemDate = new Date(item.date);
            const differenceInYears = (currentDate - itemDate) / (365 * 24 * 60 * 60 * 1000);
            return differenceInYears <= years;
        }

    });
    return filteredData;
}
const generateData = (data, date_columm = 'DateTime', field) => {
    const timeZoneOffset = 7 * 60 * 60 * 1000;
    const cdata = data.map(d => {
        let time = timeToTz(d[date_columm], 'Asia/Bangkok')

        const dd = new Date(d[date_columm])
        const year = dd.getFullYear();
        const month = String(dd.getMonth() + 1).padStart(2, '0'); // Add 1 because months are zero-indexed
        const day = String(dd.getDate()).padStart(2, '0');

        // let time2 = (new Date(d[date_columm]).getTime() + timeZoneOffset) / 1000
        let time2 = `${year}-${month}-${day}`;

        return { time: time2, value: parseFloat(d[field]) || 0 }
    });
    return cdata;
}
function timeToTz(originalTime, timeZone) {
    const zonedDate = new Date(new Date(originalTime).toLocaleString('en-US', { timeZone }));
    // const options = {
    //     timeZone: timeZone,
    //     hour: 'numeric',
    //     minute: 'numeric',
    //     second: 'numeric',
    //     hour12: false,
    // };
    // const formatter = new Intl.DateTimeFormat('en-US', options);
    return zonedDate.getTime() / 1000;
}

function extractNumberFromString(str) {
    if (str.toUpperCase() === 'ALL') {
        return 'ALL';
    }
    const match = str.match(/\d+/);
    return match ? parseInt(match[0], 10) : null;
}

const generateUniqueRandomColors = (count) => {
    //const colors = [];
    const colors = [
        "#FF5733", // Light Red
        "#FFC300", // Yellow
        "#C70039", // Red
        "#4CAF50", // Green
        "#2196F3", // Blue
        "#211717", // Orange
        "#9C27B0", // Purple
        "#795548", // Brown
        "#371B58", // Red
        //"#E91E63", // Pink
        "#1A120B", // Cyan
        //"#FFEB3B", // Yellow
        "#673AB7", // Deep Purple
        "#009688", // Teal
        "#321E1E", // Deep Orange
        "#3F51B5", // Indigo
        "#8BC34A", // Light Green
        "#607D8B", // Blue Grey
        "#1B1A55", // Pink
        //"#FF9800"  // Orange
    ];
    
    
    const usedColors = new Set();

    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    // Function to shuffle array
    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const shuffledColors = shuffleArray(colors);

    while (shuffledColors.length < count) {
        //const color = getRandomColor();
        if (!usedColors.has(colors)) {
            usedColors.add(colors);
            colors.push(colors);
        }
    }

    return colors;
};

const base_api_url =
    process.env.NODE_ENV === "production"
        ? window.location.protocol === "https:"
            ? process.env.REACT_APP_API_HTTPS_URL
            : process.env.REACT_APP_API_HTTP_URL
        : "/application";

const BidAskComponent = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [options, setOpeions] = useState([]);
    const [selectedYear, setSelectedYear] = useState('1Y');

    const [paramsQuery, setParamsQuery] = useState({
        symbol: "",
        queryType: 3,
    });

    const [selectedSymbol, setSelectedSymbol] = useState(null);

    const [groupByPetroleumName, setGroupByPetroleumName] = useState({});
    const [symbolPrice, setSymbolPrice] = useState({});

    const isEmpty = (obj) => {
        return Object.keys(obj).length === 0;
    }
    const isEmptyArray = (arr) => {
        return arr.length === 0;
    };

    const [isSearch, setIsSearch] = useState(true);

    const get_data = async (symbol, queryType) => { 


        if (symbol !== undefined || queryType !== undefined) {
            setParamsQuery(prevState => ({
                ...prevState,
                symbol: symbol !== undefined ? symbol : prevState.symbol,
                queryType: queryType !== undefined ? queryType : prevState.queryType
            }));
        }
        const response = await __fetching_data__({ url: `${base_api_url}/petroleum_report?${new URLSearchParams(paramsQuery)}`, method: "GET" });
        console.log(response,'petroleum')
        //res data /data2 is gasohol
        if (response.status) {
            let groupedData = groupByPetroleumName;
            let sortedData = symbolPrice;
            if (isEmpty(groupedData)) {
                if (response.data2) {
                    groupedData = response.data2.reduce((group, product) => {
                        const { name, date } = product;
                        if (new Date(date) instanceof Date) {
                            const trimmedName = name.trim();
                            group[trimmedName] = group[trimmedName] ?? [];
                            group[trimmedName].push(product);
                            //const keys2 = Object.keys(groupedData);
                        }
                        return group;
                    }, {});
    
                    const currentDate = new Date(); // Get current date
                    const oneMonthAgo = new Date();
                    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1); // Calculate one month ago
    
                    console.log("This is group data : ", groupedData)
    
                    groupedData = Object.keys(groupedData).reduce((filtered, key) => {
                        const products = groupedData[key];
                        // Check if any product within the group is within the past month
                        const withinPastMonth = products.some(product => new Date(product.date) >= oneMonthAgo);
                        if (withinPastMonth) {
                            filtered[key] = products;
                        }
                        return filtered;
                    }, {});
                    setGroupByPetroleumName(groupedData);
                    console.log("This is group data after reduce : ", groupedData)
                    uniqueRandomColors.current = generateUniqueRandomColors(Object.keys(groupedData).length);
    
                    if (response.data) {
                        sortedData = [...response.data].sort((a, b) => compareDatetime(a, b, 'Date'));
                        setSymbolPrice(sortedData);
                    }
    
                    let mainSeriesDummy = null;
                    let gen_data;
                    let year = extractNumberFromString(selectedYear);
                    console.log("This is sortedData: ",sortedData)
                    let dummy_data = filterByYears(sortedData, year);
                    gen_data = generateData(dummy_data, 'Date', 'price');// <- ราคาอยู่ใน column 'price'
                    mainSeriesDummy = chartInstanceRefs.current[0].addLineSeries(seriesSetting.mainSeries1);
                    mainSeriesDummy.setData(gen_data);
                    serieInstanceRefs.current.close['close'] = mainSeriesDummy;
                    //get oil name 
                    const keys2 = Object.keys(groupedData);
                    console.log("This is keys2 : ",keys2)
                    // Key Set to dropdown oil type
                    setOpeions(keys2)
                    keys2.forEach((key, index) => {
                        const value = filterByYears(groupedData[key], year);
    
                        // lastRef = serieInstanceRefs.current.length
                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        gen_data = value.sort((a, b) => compareDatetime(a, b, 'date'));
                        gen_data = generateData(gen_data, 'date', 'ex_refin');
                        // seriesSetting.mainSeries2.color = uniqueRandomColors[index];
                        // seriesSetting.mainSeries2.lastValueVisible = false;
                        setSeriesSetting(prevState => ({
                            ...prevState,
                            mainSeries2: {
                                ...prevState.mainSeries2,
                                color: uniqueRandomColors.current[index],
                                lastValueVisible: false
                            },
                        }));
                        mainSeriesDummy = chartInstanceRefs.current[1].addLineSeries(seriesSetting.mainSeries2);
                        mainSeriesDummy.setData(gen_data);
                        mainSeriesDummy.applyOptions({
                            color: uniqueRandomColors.current[index],
                            lastValueVisible: false,
                            visible: false
                        });
                        // serieInstanceRefs.current[lastRef + 1] = mainSeriesDummy;
                        serieInstanceRefs.current.ex_refin[key] = mainSeriesDummy;
                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        gen_data = value.sort((a, b) => compareDatetime(a, b, 'date'));
                        gen_data = generateData(gen_data, 'date', 'marketing');
                        setSeriesSetting(prevState => ({
                            ...prevState,
                            mainSeries3: {
                                ...prevState.mainSeries3,
                                color: uniqueRandomColors.current[index],
                                lastValueVisible: false
                            },
                        }));
                        mainSeriesDummy = chartInstanceRefs.current[2].addLineSeries(seriesSetting.mainSeries3);
                        mainSeriesDummy.setData(gen_data);
                        mainSeriesDummy.applyOptions({
                            color: uniqueRandomColors.current[index],
                            lastValueVisible: false,
                            visible: false
                        });
                        // serieInstanceRefs.current[lastRef + 2] = mainSeriesDummy;
                        serieInstanceRefs.current.mkt_margin[key] = mainSeriesDummy;
                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        gen_data = value.sort((a, b) => compareDatetime(a, b, 'date'));
                        gen_data = generateData(gen_data, 'date', 'oil_fund');
                        setSeriesSetting(prevState => ({
                            ...prevState,
                            mainSeries4: {
                                ...prevState.mainSeries4,
                                color: uniqueRandomColors.current[index],
                                lastValueVisible: false
                            },
                        }));
                        mainSeriesDummy = chartInstanceRefs.current[3].addLineSeries(seriesSetting.mainSeries4);
                        mainSeriesDummy.setData(gen_data);
                        mainSeriesDummy.applyOptions({
                            color: uniqueRandomColors.current[index],
                            lastValueVisible: false,
                            visible: false
                        });
                        // serieInstanceRefs.current[lastRef + 3] = mainSeriesDummy;
                        serieInstanceRefs.current.oil_fund[key] = mainSeriesDummy;
                    });
    
                    // Select Oil Type
                    const handleSelectionChange = (event, newSelectedOptions) => {
                        options.forEach((key, value) => {
                            serieInstanceRefs.current.ex_refin[key].applyOptions({
                                visible: false
                            });
                            serieInstanceRefs.current.mkt_margin[key].applyOptions({
                                visible: false
                            });
                            serieInstanceRefs.current.oil_fund[key].applyOptions({
                                visible: false
                            });
                        });
                        setSelectedOptions(newSelectedOptions);
    
                        newSelectedOptions.forEach((key, value) => {
                            serieInstanceRefs.current.ex_refin[key].applyOptions({
                                visible: true
                            });
                            serieInstanceRefs.current.mkt_margin[key].applyOptions({
                                visible: true
                            });
                            serieInstanceRefs.current.oil_fund[key].applyOptions({
                                visible: true
                            });
                        });
                    };
                }
            }
            if (response.data) {
                sortedData = [...response.data].sort((a, b) => compareDatetime(a, b, 'Date'));
                setSymbolPrice(sortedData);
                console.log("This is sortedData: ",sortedData)
            }
    
            try {
                setData2Charts(sortedData, groupedData);
                console.log('grpup data :',groupedData);
                // synchronization
                const synchronizeCharts = () => {
                    const keys_series = Object.keys(serieInstanceRefs.current);
                    const filteredCharts = chartInstanceRefs.current.filter((_, index) => [1, 2, 3].includes(index));
                
                    // Synchronize logical range changes across selected charts
                    filteredCharts.forEach((currentChart, currentIndex) => {
                        currentChart.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
                            filteredCharts.forEach((otherChart, otherIndex) => {
                                if (currentIndex !== otherIndex) {
                                    otherChart.timeScale().setVisibleLogicalRange(timeRange);
                                }
                            });
                        });
                    });
                
                    // Synchronize crosshair movements across selected charts
                    filteredCharts.forEach((currentChart, currentIndex) => {
                        currentChart.subscribeCrosshairMove(param => {
                            const firstKey = Object.keys(serieInstanceRefs.current[keys_series[currentIndex + 1]])[0];
                            const dataPoint = getCrosshairDataPoint(serieInstanceRefs.current[keys_series[currentIndex + 1]][firstKey], param);
                
                            if (dataPoint !== undefined && dataPoint !== null) {
                                filteredCharts.forEach((otherChart, otherIndex) => {
                                    if (currentIndex !== otherIndex) {
                                        Object.keys(serieInstanceRefs.current[keys_series[otherIndex + 1]]).forEach(key_other_serie => {
                                            if (Object.hasOwnProperty.call(serieInstanceRefs.current[keys_series[otherIndex + 1]], key_other_serie)) {
                                                syncCrosshair(otherChart, serieInstanceRefs.current[keys_series[otherIndex + 1]][key_other_serie], dataPoint);
                                            }
                                        });
                                    }
                                });
                            } 
                        });
                    });
                };
                synchronizeCharts();
            } catch (error) {
                console.log(error)
            }
    

        }
        setIsSearch(false)
        return response;
    }
    


    const [chartContainers, setChartContainers] = useState([]);
    const [chartInstances, setChartInstances] = useState([]);
    const chartInstanceRefs = useRef([]);

    const charts_option = [
        {
            width: 400,
            height: 300,
            crosshair: {
                mode: 1
            },
            timeScale: {
                visible: true,
            },
            localization: {
                locale: "en-US"
            },
            layout: {
                background: {
                    type: 'solid',
                    color: '#f5f5f5',
                },
            },
            rightPriceScale: {

                scaleMargins: {
                    top: 0.3,
                    bottom: 0.2,
                },
                borderVisible: true,
                drawTicks: false,
            },
            lastValueVisible: false,

        }, {
            width: 400,
            height: 300,
            crosshair: {
                mode: 1
            },
            timeScale: {
                visible: true
            },
            localization: {
                locale: "en-US"
            },
            layout: {
                background: {
                    type: 'solid',
                    color: '#f5f5f5',
                },
            },
            rightPriceScale: {
                scaleMargins: {
                    top: 0.3,
                    bottom: 0.2,
                },
                borderVisible: false,
                drawTicks: true,
            },
            lastValueVisible: false
        }, {
            width: 400,
            height: 300,
            crosshair: {
                mode: 1
            },
            timeScale: {
                visible: true
            },
            localization: {
                locale: "en-US"
            },
            layout: {
                background: {
                    type: 'solid',
                    color: '#f5f5f5',
                },
            },
            rightPriceScale: {
                scaleMargins: {
                    top: 0.3,
                    bottom: 0.2,
                },
                borderVisible: false,
                drawTicks: true,
            },
            lastValueVisible: false
        }, {
            width: 400,
            height: 300,
            crosshair: {
                mode: 1
            },
            timeScale: {
                visible: true
            },
            localization: {
                locale: "en-US"
            },
            layout: {
                background: {
                    type: 'solid',
                    color: '#f5f5f5',
                },
            },
            rightPriceScale: {
                scaleMargins: {
                    top: 0.3,
                    bottom: 0.2,
                },
                borderVisible: false,
                drawTicks: true,
            },
            lastValueVisible: false
        }
    ]

    const memoizedChartContainers = useMemo(() => chartContainers, [chartContainers]);
    const uniqueRandomColors = useRef([]);
    const [seriesSetting, setSeriesSetting] = useState({
        'mainSeries1': {
            color: '#d93025',
            lineWidth: 3,
            priceLineVisible: false,
            lastValueVisible: false
        },
        'mainSeries2': {
            color: '#ffc107',
            lineWidth: 3,
            priceLineVisible: false,
        },
        'mainSeries3': {
            color: '#28a745',
            lineWidth: 3,
            priceLineVisible: false,
        },
        'mainSeries4': {
            color: '#1a73e8',
            lineWidth: 3,
            priceLineVisible: false,
        },
    });
    const memoizedChartSeriesSetting = useMemo(() => seriesSetting, [seriesSetting]);

    const [selectedOptions, setSelectedOptions] = useState([]);
    const memoizedSelectedOptions = useMemo(() => selectedOptions, [selectedOptions]);

    const chart_name = ['CLOSE', 'EX-REFIN', 'Marketing Margin', 'Oil Fund'];

    useEffect(() => {
        const content = document.getElementsByClassName('content')[0];
        const resizeHandler = () => {
            chartInstanceRefs.current.forEach((chart, index) => {
                if (chart) {
                    try { chart.resize(chartContainers[index].clientWidth, chartContainers[index].clientHeight); } catch (error) { }
                }
            })
        };

        const resizeObserver = new ResizeObserver(resizeHandler);
        resizeObserver.observe(content);

        const chartAnnotation = (chart, text) => {
            chart.applyOptions({
                watermark: {
                    visible: true,
                    fontSize: 24,
                    horzAlign: 'left',
                    vertAlign: 'top',
                    color: 'rgb(0,0,0)',
                    text: text,
                },
                rightPriceScale: {
                    visible: true,
                },
                leftPriceScale: {
                    visible: true,
                    mode: PriceScaleMode.Normal,
                    autoScale: false,  // Disable automatic scaling
                    invertScale: false,
                    alignLabels: false,
                    minValue: -1,      // Set the minimum value
                    maxValue: 1,       // Set the maximum value
                },
            });

        }


        const createChartInstances = () => {
            chartContainers.forEach((container, index) => {

                 // Check if a chart instance already exists for this container
               // if (chartInstanceRefs.current[index]) {
                    // If a chart instance exists, remove it
                    //chartInstanceRefs.current[index].remove();
               // }

                const chart = createChart(container, charts_option[index ? index : 0]);
                chartAnnotation(chart, chart_name[index])
                chartInstanceRefs.current[index] = chart;
                // chart.resize(chartContainers[index].clientWidth, chartContainers[index].clientHeight);
                chart.resize(chartContainers[index].clientWidth, resChartRef.current.clientHeight / 2);
                //resChartRef.current
            });
            window.addEventListener('resize', resizeHandler);
            return () => {
                window.removeEventListener('resize', resizeHandler);
                chartInstanceRefs.current.forEach((chart) => {
                    if (chart) {
                        chart.remove();
                    }
                });
                // chartInstanceRefs.current = [];
            };

        };
        createChartInstances();
        return () => {
            get_data();
            //handleSearchSymbol();
            resizeObserver.disconnect();
            // chartInstanceRefs.current.forEach((chart) => {
            //     if (chart) {
            //         chart.remove();
            //     }
            // });
            // chartInstanceRefs = [];
        };

    }, [memoizedChartContainers]);

    useEffect(() => {
    }, [memoizedSelectedOptions]);



    const handleAddChartContainer = (element) => {
        if (!chartContainers.includes(element) && element) {
            setChartContainers((prevContainers) => [...prevContainers, element]);
        }
    };
    const handleYearChange = (event, newValue) => {
        setSelectedYear(newValue);
        
    };
    const [loading, setLoading] = useState(false);
    const handleChange = (e, selectedValue) => {
        //setLoading(true);
        // const { name, value } = e.target

        //whengetsymbol
        setParamsQuery((prevData) => ({
            ...prevData,
            'symbol': selectedValue,
            queryType: 4,
        }));
        // if (name == 'symbol') {
        //     setParamsQuery((prevData) => ({
        //         ...prevData,
        //         queryType: 2,
        //     }));
        // }
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }

    //search button fetch
    const handleSearchSymbol = () => {
        setIsSearch(true)
        get_data()
        // console.log(selectedOptions)
    }

    const [crosshairDataPoint, setCrosshairDataPoint] = useState(null);
    function getCrosshairDataPoint(series, param) {
        if (!param.time) {
            return null;
        }
        const dataPoint = param.seriesData.get(series);
        return dataPoint || null;
    }

    function syncCrosshair(chart, series, dataPoint) {
        if (dataPoint) {
            chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
            return;
        }
        chart.clearCrosshairPosition();
    }
    const handleCrosshairMove = (param, chart, series) => {
        const dataPoint = getCrosshairDataPoint(series, param);
        syncCrosshair(chart, series, dataPoint);
        setCrosshairDataPoint(dataPoint);
    };



    const seriesToggle = () => {
        console.log(selectedOptions)
        console.log(serieInstanceRefs.current)
        selectedOptions.forEach((key, value) => {
            console.log(key, value)
            // serieInstanceRefs.current.close[key].applyOptions({
            //     visible: true
            // });
            // serieInstanceRefs.current.ex_refin[key].applyOptions({
            //     visible: true
            // });
            // serieInstanceRefs.current.mkt_margin[key].applyOptions({
            //     visible: true
            // });
            // serieInstanceRefs.current.oil_fund[key].applyOptions({
            //     visible: true
            // });
        })
    }

    const LoadingIndicator = () => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <CircularProgress sx={{ color: 'blue' }} />
        </div>
    );


    const serieInstanceRefs = useRef({ close: {}, ex_refin: {}, mkt_margin: {}, oil_fund: {} });
    useEffect(() => {
    }, [serieInstanceRefs, selectedOptions]);

    const myVisibleTimeRangeChangeHandler = (timeRange) => {
        // Handle the visible time range change here
        console.log('Visible time range changed:', timeRange);
    };

    const visibleLogicalRangeChangeSubscriptions = [];

    //chart data
    const setData2Charts = (d1, d2) => {
        if (!selectedSymbol) return;
        try {
            // Object.values(serieInstanceRefs.current.close).forEach(serie => serie && serie.setData([]));
            // Object.values(serieInstanceRefs.current.ex_refin).forEach(serie => serie && serie.setData([]));
            // Object.values(serieInstanceRefs.current.mkt_margin).forEach(serie => serie && serie.setData([]));
            // Object.values(serieInstanceRefs.current.oil_fund).forEach(serie => serie && serie.setData([]));

            console.log('Existing series in chart[0] before removal:', Object.keys(serieInstanceRefs.current.close));

            Object.values(serieInstanceRefs.current.close).forEach(serie => {
                console.log(serie,'serie close')
                if(chartInstanceRefs.current[0]){
                    chartInstanceRefs.current[0].removeSeries(serie)
                    console.log(`Removed series from chart[0]: ${serie}`); // Adjust according to your series identifier
                }
                console.log('delete failed')
            });
            Object.values(serieInstanceRefs.current.ex_refin).forEach(serie => chartInstanceRefs.current[1].removeSeries(serie));
            Object.values(serieInstanceRefs.current.mkt_margin).forEach(serie => chartInstanceRefs.current[2].removeSeries(serie));
            Object.values(serieInstanceRefs.current.oil_fund).forEach(serie => chartInstanceRefs.current[3].removeSeries(serie));

        } catch (error) {
            console.log('chartdata',error)
        }
        serieInstanceRefs.current.close = {};
        serieInstanceRefs.current.ex_refin = {};
        serieInstanceRefs.current.mkt_margin = {};
        serieInstanceRefs.current.oil_fund = {};

        let mainSeriesDummy = null;
        let gen_data;
        let year = extractNumberFromString(selectedYear);


        let dummy_data = filterByYears(d1, year);
        gen_data = generateData(dummy_data, 'Date', 'Close');
        mainSeriesDummy = chartInstanceRefs.current[0].addLineSeries(seriesSetting.mainSeries1);
        mainSeriesDummy.setData(gen_data);
        serieInstanceRefs.current.close['close'] = mainSeriesDummy;

        
        const keys2 = Object.keys(d2);
        console.log("This is keys2 : ",keys2)
        // Key Set to dropdown oil type
        setOpeions(keys2)
        let lastRef = 0;
        keys2.forEach((key, index) => {
            const value = filterByYears(d2[key], year);

            // lastRef = serieInstanceRefs.current.length
            //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            gen_data = value.sort((a, b) => compareDatetime(a, b, 'date'));
            gen_data = generateData(gen_data, 'date', 'ex_refin');
            // seriesSetting.mainSeries2.color = uniqueRandomColors[index];
            // seriesSetting.mainSeries2.lastValueVisible = false;
            setSeriesSetting(prevState => ({
                ...prevState,
                mainSeries2: {
                    ...prevState.mainSeries2,
                    color: uniqueRandomColors.current[index],
                    lastValueVisible: false
                },
            }));
            mainSeriesDummy = chartInstanceRefs.current[1].addLineSeries(seriesSetting.mainSeries2);
            mainSeriesDummy.setData(gen_data);
            mainSeriesDummy.applyOptions({
                color: uniqueRandomColors.current[index],
                lastValueVisible: false,
                visible: false
            });
            // serieInstanceRefs.current[lastRef + 1] = mainSeriesDummy;
            serieInstanceRefs.current.ex_refin[key] = mainSeriesDummy;
            //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            gen_data = value.sort((a, b) => compareDatetime(a, b, 'date'));
            gen_data = generateData(gen_data, 'date', 'marketing');
            setSeriesSetting(prevState => ({
                ...prevState,
                mainSeries3: {
                    ...prevState.mainSeries3,
                    color: uniqueRandomColors.current[index],
                    lastValueVisible: false
                },
            }));
            mainSeriesDummy = chartInstanceRefs.current[2].addLineSeries(seriesSetting.mainSeries3);
            mainSeriesDummy.setData(gen_data);
            mainSeriesDummy.applyOptions({
                color: uniqueRandomColors.current[index],
                lastValueVisible: false,
                visible: false
            });
            // serieInstanceRefs.current[lastRef + 2] = mainSeriesDummy;
            serieInstanceRefs.current.mkt_margin[key] = mainSeriesDummy;
            //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            gen_data = value.sort((a, b) => compareDatetime(a, b, 'date'));
            gen_data = generateData(gen_data, 'date', 'oil_fund');
            setSeriesSetting(prevState => ({
                ...prevState,
                mainSeries4: {
                    ...prevState.mainSeries4,
                    color: uniqueRandomColors.current[index],
                    lastValueVisible: false
                },
            }));
            mainSeriesDummy = chartInstanceRefs.current[3].addLineSeries(seriesSetting.mainSeries4);
            mainSeriesDummy.setData(gen_data);
            mainSeriesDummy.applyOptions({
                color: uniqueRandomColors.current[index],
                lastValueVisible: false,
                visible: false
            });
            // serieInstanceRefs.current[lastRef + 3] = mainSeriesDummy;
            serieInstanceRefs.current.oil_fund[key] = mainSeriesDummy;



        });
        const unsubscribeChart1 = chartInstanceRefs.current[0].subscribeCrosshairMove(param => {
            // handleCrosshairMove(param, chart2, mainSeries2);
        });

        // const unsubscribeChart2 = chartInstanceRefs.current[1].subscribeCrosshairMove(param => {
        //     handleCrosshairMove(param, chart1, mainSeries1);
        // });
        // chartInstanceRefs.current[0].subscribeCrosshairMove((param) => {
        //     if (!param.time || !param.seriesPrices) return;
        //     chartInstanceRefs.current[1].subscribeCrosshairMove({
        //         time: param.time,
        //     });
        // });

        // charts.forEach((currentChart, currentIndex) => {
        //     currentChart.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
        //         charts.forEach((otherChart, otherIndex) => {
        //             if (currentIndex !== otherIndex) {
        //                 otherChart.timeScale().setVisibleLogicalRange(timeRange);
        //             }
        //         });
        //     });
        // })
        const keys_series = Object.keys(serieInstanceRefs.current);
        chartInstanceRefs.current.forEach((currentChart, currentIndex) => {
            currentChart.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
                chartInstanceRefs.current.forEach((otherChart, otherIndex) => {
                    if (currentIndex !== otherIndex) {
                        otherChart.timeScale().setVisibleLogicalRange(timeRange);
                    }
                });
            });
        })
        chartInstanceRefs.current.forEach((currentChart, currentIndex) => {
            currentChart.subscribeCrosshairMove(param => {
                const firstKey = Object.keys(serieInstanceRefs.current[keys_series[currentIndex]])[0];
                const dataPoint = getCrosshairDataPoint(serieInstanceRefs.current[keys_series[currentIndex]][firstKey], param);
                chartInstanceRefs.current.forEach((otherChart, otherIndex) => {

                    if (currentIndex !== otherIndex) {
                        for (const key_other_serie in serieInstanceRefs.current[keys_series[otherIndex]]) {
                            if (Object.hasOwnProperty.call(serieInstanceRefs.current[keys_series[otherIndex]], key_other_serie)) {
                                syncCrosshair(otherChart, serieInstanceRefs.current[keys_series[otherIndex]][key_other_serie], dataPoint);
                            }
                        }
                    }

                });
            });
        });
        chartInstanceRefs.current[0].timeScale().fitContent();
        chartInstanceRefs.current[1].timeScale().fitContent();
        chartInstanceRefs.current[2].timeScale().fitContent();
        chartInstanceRefs.current[3].timeScale().fitContent();
        // chartInstanceRefs.current.forEach((currentChart, currentIndex) => {
        //     const timeScale = currentChart.timeScale();
        //     if (timeScale) {
        //         const logicalRange = timeScale.getVisibleLogicalRange();
        //         if (logicalRange) {
        //             const handler = timeScale.subscribeVisibleLogicalRangeChange(timeRange => {
        //                 chartInstanceRefs.current.forEach((otherChart, otherIndex) => {
        //                     if (currentIndex !== otherIndex) {
        //                         otherChart.timeScale().setVisibleLogicalRange(timeRange);
        //                     }
        //                 });
        //             });
        //             visibleLogicalRangeChangeSubscriptions.push({ chartInstance: currentChart, subscription: handler });
        //         } else {
        //             // console.error("Logical range is null or undefined.");
        //         }

        //     }
        // })


        chartInstanceRefs.current.forEach((currentChart, currentIndex) => {
            currentChart.subscribeCrosshairMove(param => {
                // const firstKey = Object.keys(serieInstanceRefs.current[keys_series[currentIndex]])[0];
                // const dataPoint = getCrosshairDataPoint(serieInstanceRefs.current[keys_series[currentIndex]][firstKey], param);
                // chartInstanceRefs.current.forEach((otherChart, otherIndex) => {
                //     if (currentIndex !== otherIndex) {
                //         for (const key_other_serie in serieInstanceRefs.current[keys_series[otherIndex]]) {
                //             if (Object.hasOwnProperty.call(serieInstanceRefs.current[keys_series[otherIndex]], key_other_serie)) {
                //                 syncCrosshair(otherChart, serieInstanceRefs.current[keys_series[otherIndex]][key_other_serie], dataPoint);
                //             }
                //         }
                //     }
                // });
                // for (const key_main_serie in serieInstanceRefs.current[keys_series[currentIndex]]) {
                //     if (Object.hasOwnProperty.call(serieInstanceRefs.current[keys_series[currentIndex]], key_main_serie)) {
                //         const dataPoint = getCrosshairDataPoint(serieInstanceRefs.current[keys_series[currentIndex]][key_main_serie], param);
                //         chartInstanceRefs.current.forEach((otherChart, otherIndex) => {
                //             if (currentIndex !== otherIndex) {
                //                 for (const key_other_serie in serieInstanceRefs.current[keys_series[otherIndex]]) {
                //                     if (Object.hasOwnProperty.call(serieInstanceRefs.current[keys_series[otherIndex]], key_other_serie)) {
                //                         syncCrosshair(otherChart, serieInstanceRefs.current[keys_series[otherIndex]][key_other_serie], dataPoint);
                //                     }
                //                 }
                //             }
                //         });
                //     }
                // }
            });
        });
        chartInstanceRefs.current[0].subscribeCrosshairMove(param => {
            const firstKey = Object.keys(serieInstanceRefs.current[keys_series[0]])[0];
            const dataPoint = getCrosshairDataPoint(serieInstanceRefs.current[keys_series[0]][firstKey], param);
            for (const key in serieInstanceRefs.current) {
                if (Object.hasOwnProperty.call(serieInstanceRefs.current, key)) {
                    for (const key2 in serieInstanceRefs.current[key]) {
                        if (Object.hasOwnProperty.call(serieInstanceRefs.current[key], key2)) {
                            if (key == 'ex_refin') {
                                syncCrosshair(chartInstanceRefs.current[1], serieInstanceRefs.current[key][key2], dataPoint);
                            }
                            if (key == 'mkt_margin') {
                                syncCrosshair(chartInstanceRefs.current[2], serieInstanceRefs.current[key][key2], dataPoint);
                            }
                            if (key == 'oil_fund') {
                                syncCrosshair(chartInstanceRefs.current[3], serieInstanceRefs.current[key][key2], dataPoint);
                            }
                        }
                    }
                }
            }
        });
        // tewwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww
        // chartInstanceRefs.current[1].subscribeCrosshairMove(param => {
        //     const secondKey = Object.keys(serieInstanceRefs.current[keys_series[0]])[1];
        //     const dataPoint = getCrosshairDataPoint(serieInstanceRefs.current[keys_series[1]][secondKey], param);
        //     console.log(dataPoint,'tew');
        //     for (const key in serieInstanceRefs.current) {
        //         if (Object.hasOwnProperty.call(serieInstanceRefs.current, key)) {
        //             for (const key2 in serieInstanceRefs.current[key]) {
        //                 if (Object.hasOwnProperty.call(serieInstanceRefs.current[key], key2)) {
        //                     if (key == 'close') {
        //                         syncCrosshair(chartInstanceRefs.current[0], serieInstanceRefs.current[key][key2], dataPoint);
        //                     }
        //                     if (key == 'mkt_margin') {
        //                         syncCrosshair(chartInstanceRefs.current[2], serieInstanceRefs.current[key][key2], dataPoint);
        //                     }
        //                     if (key == 'oil_fund') {
        //                         syncCrosshair(chartInstanceRefs.current[3], serieInstanceRefs.current[key][key2], dataPoint);
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // })
        // tewwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww

        // chartInstanceRefs.current[1].subscribeCrosshairMove(param => {
        // const lastKey = Object.keys(serieInstanceRefs.current[keys_series[1]])[Object.keys(serieInstanceRefs.current[keys_series[1]]).length - 1];
        // const dataPoint = getCrosshairDataPoint(serieInstanceRefs.current[keys_series[1]][lastKey], param);
        // console.log(dataPoint)
        // for (const key in serieInstanceRefs.current) {
        //     if (Object.hasOwnProperty.call(serieInstanceRefs.current, key)) {
        //         for (const key2 in serieInstanceRefs.current[key]) {
        //             if (Object.hasOwnProperty.call(serieInstanceRefs.current[key], key2)) {
        //                 if (key == 'close') {
        //                     syncCrosshair(chartInstanceRefs.current[0], serieInstanceRefs.current[key][key2], dataPoint);
        //                 }
        //                 if (key == 'mkt_margin') {
        //                     syncCrosshair(chartInstanceRefs.current[2], serieInstanceRefs.current[key][key2], dataPoint);
        //                 }
        //                 if (key == 'oil_fund') {
        //                     syncCrosshair(chartInstanceRefs.current[3], serieInstanceRefs.current[key][key2], dataPoint);
        //                 }
        //             }
        //         }
        //     }
        // }
        // for (const key_main in serieInstanceRefs.current[keys_series[1]]) {
        // if (Object.hasOwnProperty.call(serieInstanceRefs.current[keys_series[1]], key_main)) {
        // const element = object[key];
        // const dataPoint = getCrosshairDataPoint(serieInstanceRefs.current[keys_series[1]][key_main], param);
        // console.log(dataPoint)
        // for (const key in serieInstanceRefs.current) {
        //     if (Object.hasOwnProperty.call(serieInstanceRefs.current, key)) {
        //         for (const key2 in serieInstanceRefs.current[key]) {
        //             if (Object.hasOwnProperty.call(serieInstanceRefs.current[key], key2)) {
        //                 if (key == 'close') {
        //                     syncCrosshair(chartInstanceRefs.current[0], serieInstanceRefs.current[key][key2], dataPoint);
        //                 }
        //                 if (key == 'mkt_margin') {
        //                     syncCrosshair(chartInstanceRefs.current[2], serieInstanceRefs.current[key][key2], dataPoint);
        //                 }
        //                 if (key == 'oil_fund') {
        //                     syncCrosshair(chartInstanceRefs.current[3], serieInstanceRefs.current[key][key2], dataPoint);
        //                 }
        //             }
        //         }
        //     }
        // }
        //     }
        // }
        // });
     {/*
        useEffect(() => {
            console.log("This is selectedOptions value inside charts create : ",selectedOptions)
        },[selectedOptions]);

   
        options.forEach((key, value) => {
            serieInstanceRefs.current.ex_refin[key].applyOptions({
                visible: false
            });
            serieInstanceRefs.current.mkt_margin[key].applyOptions({
                visible: false
            });
            serieInstanceRefs.current.oil_fund[key].applyOptions({
                visible: false
            });
        });*/}

        selectedOptions.forEach((key, value) => {
            serieInstanceRefs.current.ex_refin[key].applyOptions({
                visible: true
            });
            serieInstanceRefs.current.mkt_margin[key].applyOptions({
                visible: true
            });
            serieInstanceRefs.current.oil_fund[key].applyOptions({
                visible: true
            });
        });
    }

    //Auto fetch data to chart
    useEffect(() => {
        handleSearchSymbol();
        get_data();
        //handleSelectionChange();
        //handleSearchSymbol();
        //setData2Charts();
      }, [selectedSymbol, selectedYear]);

    
    {/*
    useEffect(() =>{
        console.log("This is Options value : ",options)
        console.log("This is selectedOptions value : ",selectedOptions)
    },[options, selectedOptions]);*/}


    function syncCrosshair(chart, series, dataPoint) {
        if (dataPoint) {
            chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
            return;
        }
        chart.clearCrosshairPosition();
    }
    function getCrosshairDataPoint(series, param) {
        if (!param.time) {
            return null;
        }
        const dataPoint = param.seriesData.get(series);
        return dataPoint || null;
    }

    // Select Oil Type
    const handleSelectionChange = (event, newSelectedOptions) => {
        options.forEach((key, value) => {
            serieInstanceRefs.current.ex_refin[key].applyOptions({
                visible: false
            });
            serieInstanceRefs.current.mkt_margin[key].applyOptions({
                visible: false
            });
            serieInstanceRefs.current.oil_fund[key].applyOptions({
                visible: false
            });
        });
        setSelectedOptions(newSelectedOptions);

        newSelectedOptions.forEach((key, value) => {
            serieInstanceRefs.current.ex_refin[key].applyOptions({
                visible: true
            });
            serieInstanceRefs.current.mkt_margin[key].applyOptions({
                visible: true
            });
            serieInstanceRefs.current.oil_fund[key].applyOptions({
                visible: true
            });
        });

    };

    // Get Symbol
    // const symbols = ['AAPL', 'GOOGL', 'MSFT', 'AMZN', 'FB'];['7UP', 'PTT', 'PTTCG']
    const [symbols, setSymbols] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await __fetching_data__({ url: `${base_api_url}/petroleum_symbol_list`, method: "GET" });
                console.log(response);
                if (response.status) {
                    response.data.forEach((val, index) => {
                        setSymbols((prevSymbols) => [...prevSymbols, val.SymbolName]);
                    });
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    const resChartRef = useRef(null);
    const resToolsRef = useRef(null);
    const [gridHeight, setGridHeight] = useState(0);
    useEffect(() => {
        // Ensure the reference is available
        if (!resChartRef.current) return;

        // Get the height of the Grid component
        const height = resChartRef.current.getBoundingClientRect().height;
        setGridHeight(height);


        const remainingHeight = `calc(100vh - ${95}px)`;
        console.log(remainingHeight)
        resChartRef.current.style.height = remainingHeight;
    }, []);

    return (
        <Box m="20px">
            {/* <Box display="flex" justifyContent="space-between" alignItems="center"> */}
            {/* <Header title="Chart Petroleum" subtitle="" /> */}

            {/* <Box>
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                    >
                        <DownloadOutlinedIcon sx={{ mr: "10px" }} />
                        Download Reports
                    </Button>
                </Box> */}
            {/* </Box> */}
            <Box display="block">
                <Grid container spacing={2}
                    sx={{
                        marginBottom: "1rem"
                    }}
                    ref={resToolsRef}
                >
                    <Grid item xs={4} md={2}>
                        {/* <TextField
                            fullWidth
                            id="symbol"
                            name="symbol"
                            label="Symbol"
                            variant="outlined"
                            required
                            size='small'
                            value={paramsQuery.symbol}
                            onChange={handleChange}
                            autoComplete='off'
                            InputProps={{
                                list: 'symbols-list', // ID of the datalist
                                inputProps: {
                                    maxLength: 5, // Example additional input prop
                                }
                            }}
                        />
                        <datalist id="symbols-list">
                            <option value="Option 1" />
                            <option value="Option 2" />
                            <option value="Option 3" />
                        </datalist> */}
                        <Autocomplete
                            id="symbol"
                            options={symbols}
                            autoComplete
                            autoHighlight
                            fullWidth
                            inputValue={paramsQuery.symbol}
                            onInputChange={handleChange}
                            onChange={(event, newValue) => {
                                setSelectedSymbol(newValue);
                              }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Symbol"
                                    variant="outlined"
                                    required
                                    size="small"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {/* {loading ? (
                                                    <CircularProgress color="inherit" size={20} />
                                                ) : null} */}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                            renderOption={(props, option) => (
                                <MenuItem {...props}>
                                    <Typography variant="inherit">{option}</Typography>
                                </MenuItem>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                            <DatePicker
                                id="start_date"
                                name="start_date"
                                label="Start Date"
                                slotProps={{ textField: { size: 'small' } }}
                                defaultValue={initialValues.start_date}
                                maxDate={today_value}
                                onChange={(value) => handleDateChange('start_date', value)}
                            />

                        </LocalizationProvider> */}
                        <Autocomplete
                            multiple
                            options={options}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Select oil type"
                                    placeholder="select oil type"
                                />
                            )}
                            // PopperComponent={(props) => (
                            //     <Popper {...props} sx={{ maxHeight: 200, overflowY: 'auto' }} />
                            // )}
                            size='small'
                            onChange={handleSelectionChange}
                        />
                    </Grid>

                    <Grid item xs={4} md={2}>
                        <ToggleButtonGroup
                            value={selectedYear}
                            exclusive
                            onChange={handleYearChange}
                            aria-label="Basic radio toggle button group"
                            size="small"
                            fullWidth
                        >
                            <ToggleButton value="1Y" aria-label="1Y" >
                                1Y
                            </ToggleButton>
                            {/* <ToggleButton value="3Y" aria-label="3Y" >
                                3Y
                            </ToggleButton>
                            <ToggleButton value="5Y" aria-label="5Y" >
                                5Y
                            </ToggleButton> */}
                            <ToggleButton value="ALL" aria-label="ALL" >
                                MAX
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={4} md={2}>
                        {/*
                        <Button variant="contained"
                            sx={{
                                backgroundColor: colors.blueAccent[500]
                            }}
                            type="button"
                            onClick={handleSearchSymbol}
                        >
                            Search
                        </Button>*/}
                    </Grid>
                </Grid>

                {/* <Grid container spacing={2}
                    sx={{
                        marginBottom: "1rem"

                    }}>
                    <Grid item xs={4} md={2}>
                        <ToggleButtonGroup
                            value={selectedYear}
                            exclusive
                            onChange={handleYearChange}
                            aria-label="Basic radio toggle button group"
                            size="small"
                            fullWidth
                        >
                            <ToggleButton value="1Y" aria-label="1Y" >
                                1Y
                            </ToggleButton>
                            <ToggleButton value="3Y" aria-label="3Y" >
                                3Y
                            </ToggleButton>
                            <ToggleButton value="5Y" aria-label="5Y" >
                                5Y
                            </ToggleButton>
                            <ToggleButton value="ALL" aria-label="ALL" >
                                ALL
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <Button variant="contained"
                            sx={{
                                backgroundColor: colors.blueAccent[500]
                            }}
                            type="button"
                            onClick={handleSearchSymbol}
                        >
                            Search
                        </Button>
                    </Grid>

                </Grid> */}

                <Grid
                    ref={resChartRef}
                    container
                    spacing={0}
                    sx={{
                        // marginBottom: "1rem",
                        // backgroundColor: "red",
                        // height: 'calc(100vh - var(--top-bar-height))'
                    }}

                >


                    <Grid item xs={12} md={6}>
                        {isSearch && (
                            <LoadingIndicator />
                        )}
                        <div id="chart-close" ref={(element) => handleAddChartContainer(element)}></div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div id="chart-ex-refin"  ref={(element) => handleAddChartContainer(element)}></div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div id="chart-marketing-margin" ref={(element) => handleAddChartContainer(element)}></div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div id="chart-oil-fund" ref={(element) => handleAddChartContainer(element)}></div>
                    </Grid>



                </Grid>

            </Box>
        </Box>


    )

}
export default BidAskComponent;