import { useEffect, useState, forwardRef } from "react";
import {
    Typography,
    TextField,
    Button,
    Container,
    useTheme,
    useMediaQuery,
    MenuItem,
    InputAdornment,
    OutlinedInput,
    Autocomplete,
    Select,
    FormControl,
    InputLabel,
    Grid,
    Paper,
    Box,
    FormGroup,
    List,
    ListItem,
    ListItemText,
    Popover,
    Menu,
    Tab,
    Tabs,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
} from "@mui/material";
import LinearProgress, {
    linearProgressClasses,
} from "@mui/material/LinearProgress";

import { __fetching_data__ } from "../../../components/api.js";
import { useSelector, useDispatch } from "react-redux";
import { setCsrfToken, setLogin, setUserDetail } from "../../../state";


import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TabAPI = ({
    userDetail,
    colors,
    isMobile,
    onTabDataChange
}) => {
    const dispatch = useDispatch();
    const [localUserDetail, setLocalUserDetail] = useState(userDetail);
    const [reqAppId, setReqAppId] = useState(false);
    const csrfToken = useSelector((state) => state.csrfToken);

    const base_api_url =
        process.env.NODE_ENV === "production"
            ? window.location.protocol === "https:"
                ? process.env.REACT_APP_API_HTTPS_URL
                : process.env.REACT_APP_API_HTTP_URL
            : "/application";

    const handleSubmit = async () => {
        // const url = `/application/genapikey`;

        const response = await __fetching_data__({
            url: `${base_api_url}/genapikey`,
            method: "POST",
            headers: { "xsrf-token": csrfToken },
        });
        if (response.status) {
            setLocalUserDetail((prevData) => ({
                ...prevData,
                app_id: response.data.app_id,
                app_secret: response.data.app_secret,
            }))
            onTabDataChange({
                app_id: response.data.app_id,
                app_secret: response.data.app_secret,
            });
            setReqAppId(true);
            setOpen(false);

            // dispatch(setUserDetail({
            //     ...userDetail,
            //     app_id: response.data.app_id,
            // }));
        }

    };
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const theme = useTheme();
    const dark = theme.palette.neutral.dark;
    const medium = theme.palette.neutral.medium;
    const main = theme.palette.neutral.main;

    useEffect(() => {
        if (localUserDetail.app_secret && localUserDetail.app_id) {
            setReqAppId(true);
        }



    }, []);


    const handleCopyClick = (event, tooltipSetter) => {
        event.target.select();
        document.execCommand('copy');
        tooltipSetter(true);
        setTimeout(() => {
            tooltipSetter(false);
        }, 1500);
    };
    const [isTooltipOpenAppId, setIsTooltipOpenAppId] = useState(false);
    const [isTooltipOpenAppSecret, setIsTooltipOpenAppSecret] = useState(false);


    return (
        <Grid container>
            {/** row ================================================================================================================================== */}
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    {!reqAppId ? (
                        <Box>
                            <Box display="flex" alignItems="center" gap="1rem">
                                <Typography>{"Application ID : "}</Typography>
                                <Typography>{localUserDetail?.app_id}</Typography>
                            </Box>
                            <Box display="flex" alignItems="center" gap="1rem">
                                <Button
                                    variant="contained"
                                    onClick={handleClickOpen}
                                    sx={{
                                        backgroundColor: colors.blueAccent[400],
                                        // color: colors.primary[500],
                                    }}
                                >
                                    สร้าง Application ID
                                </Button>
                            </Box>
                        </Box>
                    ) : (
                        localUserDetail?.app_secret &&
                        localUserDetail?.app_id && (
                            <Box>
                                <Box display="" alignItems="center" gap="1rem" mb="2rem">
                                    <Typography color={medium}>{"Application ID : "}</Typography>
                                    <Tooltip
                                        open={isTooltipOpenAppId}
                                        title="Copied!"
                                        placement="top"
                                        arrow
                                        PopperProps={{
                                            disablePortal: true,
                                        }}
                                    >
                                        <TextField
                                            // label="Application ID"
                                            value={localUserDetail?.app_id}
                                            onClick={(event) => handleCopyClick(event, setIsTooltipOpenAppId)}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                width: '50%',
                                            }}
                                        />
                                    </Tooltip>
                                </Box>

                                <Box display="" alignItems="center" gap="1rem" >
                                    <Typography color={medium}>{"Application Secret: "}</Typography>
                                    <Tooltip
                                        open={isTooltipOpenAppSecret}
                                        title="Copied!"
                                        placement="top"
                                        arrow
                                        PopperProps={{
                                            disablePortal: true,
                                        }}
                                    >
                                        <TextField
                                            // label="Application Secret"
                                            value={localUserDetail?.app_secret}
                                            onClick={(event) => handleCopyClick(event, setIsTooltipOpenAppSecret)}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                width: '50%',
                                            }}
                                        />
                                    </Tooltip>
                                </Box>
                                <Box display="flex" alignItems="center" gap="1rem">
                                    <Typography color={"red"}>
                                        {"* โปรดบันทึก Application Secret ไว้ก่อนเปลี่ยนไปหน้าอื่น "}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" gap="1rem">
                                    <Button
                                        variant="contained"
                                        onClick={handleClickOpen}
                                        color="secondary"
                                    >
                                        สร้าง Application ID
                                    </Button>
                                </Box>
                            </Box>
                        )
                    )}
                </Grid>

            </Grid>
            {/** row ================================================================================================================================== */}

            <Box>
                <div>
                    <Dialog
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle>
                            {"ต้องการที่จะสร้าง Application ID ใหม่ หรือไม่?"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" color="primary" onClick={handleClose}>
                                ยกเลิก
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleSubmit}
                            >
                                ตกลง
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Box>
        </Grid>
    );
};

export default TabAPI;
