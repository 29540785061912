import { useEffect, useState, useRef, useMemo } from 'react';
import { tokens } from "../../theme";
import Header from "../../components/Header";
import {
    useTheme, Box, Container, Grid, Card, TextField, Button, Table, TableHead, TableRow, TableCell, TableBody, Typography, Paper, Autocomplete,
    ToggleButton, ToggleButtonGroup, Popper, CircularProgress, MenuItem
} from '@mui/material';

import { createChart, CrosshairMode, PriceScaleMode } from 'lightweight-charts';

import { useFetchingData } from '../../components/api';
import Preloader from '../../components/Preloader';

const compareDatetime = (a, b, field = 'DateTime') => {
    const datetimeA = new Date(a[field]);
    const datetimeB = new Date(b[field]);
    return datetimeA - datetimeB;
}
const filterByYears = (data, years) => {
    if (years === 'ALL') {
        return data; // Return all data when 'ALL' is specified
    }
    const currentDate = new Date();
    const filteredData = data.filter(item => {
        if (item.hasOwnProperty('Date')) {
            const itemDate = new Date(item.Date);
            const differenceInYears = (currentDate - itemDate) / (365 * 24 * 60 * 60 * 1000);
            return differenceInYears <= years;
        }
        if (item.hasOwnProperty('date')) {
            const itemDate = new Date(item.date);
            const differenceInYears = (currentDate - itemDate) / (365 * 24 * 60 * 60 * 1000);
            return differenceInYears <= years;
        }

    });
    return filteredData;
}
const generateData = (data, date_columm = 'DateTime', field) => {
    const timeZoneOffset = 7 * 60 * 60 * 1000;
    const cdata = data.map(d => {
        let time = timeToTz(d[date_columm], 'Asia/Bangkok')

        const dd = new Date(d[date_columm])
        const year = dd.getFullYear();
        const month = String(dd.getMonth() + 1).padStart(2, '0'); // Add 1 because months are zero-indexed
        const day = String(dd.getDate()).padStart(2, '0');

        // let time2 = (new Date(d[date_columm]).getTime() + timeZoneOffset) / 1000
        let time2 = `${year}-${month}-${day}`;

        return { time: time2, value: parseFloat(d[field]) || 0 }
    });
    return cdata;
}
function timeToTz(originalTime, timeZone) {
    const zonedDate = new Date(new Date(originalTime).toLocaleString('en-US', { timeZone }));
    // const options = {
    //     timeZone: timeZone,
    //     hour: 'numeric',
    //     minute: 'numeric',
    //     second: 'numeric',
    //     hour12: false,
    // };
    // const formatter = new Intl.DateTimeFormat('en-US', options);
    return zonedDate.getTime() / 1000;
}

function extractNumberFromString(str) {
    if (str.toUpperCase() === 'ALL') {
        return 'ALL';
    }
    const match = str.match(/\d+/);
    return match ? parseInt(match[0], 10) : null;
}

const generateUniqueRandomColors = (count) => {
    const colors = [];
    const usedColors = new Set();

    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    while (colors.length < count) {
        const color = getRandomColor();
        if (!usedColors.has(color)) {
            usedColors.add(color);
            colors.push(color);
        }
    }

    return colors;
};

const base_api_url =
    process.env.NODE_ENV === "production"
        ? window.location.protocol === "https:"
            ? process.env.REACT_APP_API_HTTPS_URL
            : process.env.REACT_APP_API_HTTP_URL
        : "/application";

const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
}
const isEmptyArray = (arr) => {
    return arr.length === 0;
};

const ThaiRubberChartComponent = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { __fetching_data__ } = useFetchingData();

    const [loading, setLoading] = useState(false);

    const [selectedSymbol, setSelectedSymbol] = useState(null);

    const [options, setOpeions] = useState([]);
    const [selectedYear, setSelectedYear] = useState('ALL');


    const initialParamsQuery = {
        symbol: "",
        queryType: 1,
    }
    const [paramsQuery, setParamsQuery] = useState(initialParamsQuery);



    // const [chartInstances, setChartInstances] = useState([]);
    const uniqueRandomColors = useRef([]);

    const chartInstanceRefs = useRef([]);
    const [chartContainers, setChartContainers] = useState([]);
    const memoizedChartContainers = useMemo(() => chartContainers, [chartContainers]);
    const charts_option = [
        {
            width: 400,
            height: 300,
            crosshair: {
                mode: 1
            },
            timeScale: {
                visible: true,
            },
            localization: {
                locale: "en-US"
            },
            layout: {
                background: {
                    type: 'solid',
                    color: '#f5f5f5',
                },
            },
            rightPriceScale: {
                scaleMargins: {
                    top: 0.3,
                    bottom: 0.2,
                },
                borderVisible: true,
                drawTicks: false,
            },
            lastValueVisible: false,

        }, {
            width: 400,
            height: 300,
            crosshair: {
                mode: 1
            },
            timeScale: {
                visible: true
            },
            localization: {
                locale: "en-US"
            },
            layout: {
                background: {
                    type: 'solid',
                    color: '#f5f5f5',
                },
            },
            rightPriceScale: {
                scaleMargins: {
                    top: 0.3,
                    bottom: 0.2,
                },
                borderVisible: false,
                drawTicks: true,
            },
            lastValueVisible: false
        }, {
            width: 400,
            height: 300,
            crosshair: {
                mode: 1
            },
            timeScale: {
                visible: true
            },
            localization: {
                locale: "en-US"
            },
            layout: {
                background: {
                    type: 'solid',
                    color: '#f5f5f5',
                },
            },
            rightPriceScale: {
                scaleMargins: {
                    top: 0.3,
                    bottom: 0.2,
                },
                borderVisible: false,
                drawTicks: true,
            },
            lastValueVisible: false
        }, {
            width: 400,
            height: 300,
            crosshair: {
                mode: 1
            },
            timeScale: {
                visible: true
            },
            localization: {
                locale: "en-US"
            },
            layout: {
                background: {
                    type: 'solid',
                    color: '#f5f5f5',
                },
            },
            rightPriceScale: {
                scaleMargins: {
                    top: 0.3,
                    bottom: 0.2,
                },
                borderVisible: false,
                drawTicks: true,
            },
            lastValueVisible: false
        }
    ]
    const deleteExitingline = () => {
        try {
            Object.values(serieInstanceRefs.current.close).forEach(serie => {
                if (chartInstanceRefs.current[0]) {
                    chartInstanceRefs.current[0].removeSeries(serie);

                } else {
                    console.error('Deleting Error');
                }
            });

            Object.values(serieInstanceRefs.current.rubber_raw).forEach(serie => {
                if (chartInstanceRefs.current[1]) {
                    chartInstanceRefs.current[1].removeSeries(serie);

                } else {
                    console.error('Deleting Error');
                }
            });

            serieInstanceRefs.current.close = {};
            serieInstanceRefs.current.rubber_raw = {};
        } catch (error) {
            console.error('Deleting Error', error);
        }
    }





    const serieInstanceRefs = useRef({ close: {}, rubber_raw: {} });
    const [seriesSetting, setSeriesSetting] = useState({
        'mainSeries1': {
            color: '#d93025',
            lineWidth: 3,
            priceLineVisible: false,
            lastValueVisible: false
        },
        'mainSeries2': {
            color: '#ffc107',
            lineWidth: 3,
            priceLineVisible: false,
        },
        'mainSeries3': {
            color: '#28a745',
            lineWidth: 3,
            priceLineVisible: false,
        },
        'mainSeries4': {
            color: '#1a73e8',
            lineWidth: 3,
            priceLineVisible: false,
        },
    });
    const memoizedChartSeriesSetting = useMemo(() => seriesSetting, [seriesSetting]);

    const [selectedOptions, setSelectedOptions] = useState([]);
    const memoizedSelectedOptions = useMemo(() => selectedOptions, [selectedOptions]);

    const chart_name = ['CLOSE', 'Rubber Thai',];

    useEffect(() => {
        const content = document.getElementsByClassName('content')[0];
        const resizeHandler = () => {
            chartInstanceRefs.current.forEach((chart, index) => {
                if (chart) {
                    try { chart.resize(chartContainers[index].clientWidth, chartContainers[index].clientHeight); } catch (error) { }
                }
            })
        };

        const resizeObserver = new ResizeObserver(resizeHandler);
        resizeObserver.observe(content);

        const chartAnnotation = (chart, text) => {
            chart.applyOptions({
                watermark: {
                    visible: true,
                    fontSize: 24,
                    horzAlign: 'left',
                    vertAlign: 'top',
                    color: 'rgb(0,0,0)',
                    text: text,
                },
                rightPriceScale: {
                    visible: true,
                },
                leftPriceScale: {
                    visible: true,
                    mode: PriceScaleMode.Normal,
                    autoScale: false,  // Disable automatic scaling
                    invertScale: false,
                    alignLabels: false,
                    minValue: -1,      // Set the minimum value
                    maxValue: 1,       // Set the maximum value
                },
            });

        }


        const createChartInstances = () => {
            chartContainers.forEach((container, index) => {
                const chart = createChart(container, charts_option[index ? index : 0]);
                chartAnnotation(chart, chart_name[index])
                chartInstanceRefs.current[index] = chart;
                // chart.resize(chartContainers[index].clientWidth, chartContainers[index].clientHeight);
                chart.resize(chartContainers[index].clientWidth, resChartRef.current.clientHeight / 2);
                //resChartRef.current
            });
            window.addEventListener('resize', resizeHandler);
            return () => {
                window.removeEventListener('resize', resizeHandler);
                chartInstanceRefs.current.forEach((chart) => {
                    if (chart) {
                        chart.remove();
                    }
                });
                // chartInstanceRefs.current = [];
            };

        };
        createChartInstances();
        return () => {
            resizeObserver.disconnect();
            chartInstanceRefs.current.forEach((chart) => {
                if (chart) {
                    chart.remove();
                }
            });
            // chartInstanceRefs = [];
        };

    }, [memoizedChartContainers]);

    const handleAddChartContainer = (element) => {
        if (!chartContainers.includes(element) && element) {
            setChartContainers((prevContainers) => [...prevContainers, element]);
        }
    };



    const handleSearchSymbol = () => {
        get_data();
    }

    const [resultClosePrice, setResultClosePrice] = useState([]);
    const [resultRubber, setResultRubber] = useState([]);

    const get_data = async (symbol) => {
        setLoading(true);
        try {
            serieInstanceRefs.current.close['close'].setData([]);
            serieInstanceRefs.current.rubber_raw['ruber-0'].setData([]);
        } catch (error) {

        }
        let values = {
            'symbol': symbol || paramsQuery.symbol,
            'queryType': (symbol || paramsQuery.symbol) ? 2 : 1
        };
        // console.log(selectedSymbol, values)
        // setTimeout(() => {
        //     setLoading(false);
        // }, 3000);
        // return

        deleteExitingline();
        const response = await __fetching_data__({
            url: `${base_api_url}/rubber_test?${new URLSearchParams(values)}`,
            method: "GET"
        });
        console.log(response)
        if (response.status) {
            setResultClosePrice(response.data.close_price);
            setResultRubber(response.data.rubber);
        }

    };

    const setData2Charts = () => {
        let gen_data;
        let mainSeriesDummy;

        if (chartInstanceRefs.current[0]) {
            gen_data = generateData(resultClosePrice, 'Date', 'Close');
            mainSeriesDummy = chartInstanceRefs.current[0].addLineSeries(seriesSetting.mainSeries1);
            mainSeriesDummy.setData(gen_data);
            mainSeriesDummy.applyOptions({
                // color: uniqueRandomColors.current[0],
                lastValueVisible: false,
                visible: paramsQuery.queryType == 1 ? false : true
            });

            serieInstanceRefs.current.close['close'] = mainSeriesDummy;
        }

        gen_data = resultRubber.sort((a, b) => compareDatetime(a, b, 'Date'));
        gen_data = generateData(gen_data, 'Date', 'rubber_raw_local');
        // console.log(gen_data)
        if (chartInstanceRefs.current[1]) {
            mainSeriesDummy = chartInstanceRefs.current[1].addLineSeries(seriesSetting.mainSeries5);
            mainSeriesDummy.setData(gen_data);
            mainSeriesDummy.applyOptions({
                color: uniqueRandomColors.current[0],
                lastValueVisible: false,
                visible: true
            });

            //     // const uniqueKey = `${'rubber'}-${0}`;
            //     // serieInstanceRefs.current.rubber_raw[uniqueKey] = mainSeriesDummy;
            serieInstanceRefs.current.rubber_raw['ruber-0'] = mainSeriesDummy;
        } else {
            console.error('Chart instance for chart[1] is not available for adding series');
        }

        chartInstanceRefs.current.forEach((currentChart, currentIndex) => {
            currentChart.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
                chartInstanceRefs.current.forEach((otherChart, otherIndex) => {
                    if (currentIndex !== otherIndex) {
                        otherChart.timeScale().setVisibleLogicalRange(timeRange);
                    }
                });
            });
        })

        try {
            const keys_series = Object.keys(serieInstanceRefs.current);
            // Synchronize crosshair movement across charts
            chartInstanceRefs.current.forEach((currentChart, currentIndex) => {
                currentChart.subscribeCrosshairMove(param => {
                    const firstKey = Object.keys(serieInstanceRefs.current[keys_series[currentIndex]])[0];
                    const dataPoint = getCrosshairDataPoint(serieInstanceRefs.current[keys_series[currentIndex]][firstKey], param);
                    chartInstanceRefs.current.forEach((otherChart, otherIndex) => {
                        if (currentIndex !== otherIndex) {
                            for (const key_other_serie in serieInstanceRefs.current[keys_series[otherIndex]]) {
                                if (Object.hasOwnProperty.call(serieInstanceRefs.current[keys_series[otherIndex]], key_other_serie)) {
                                    syncCrosshair(otherChart, serieInstanceRefs.current[keys_series[otherIndex]][key_other_serie], dataPoint);
                                }
                            }
                        }
                    });
                });
            });
            chartInstanceRefs.current[0].timeScale().fitContent();
            chartInstanceRefs.current[1].timeScale().fitContent();
            // console.log(123)
            setLoading(false);
        } catch (error) {

        }

        
    }

    useEffect(() => {
        get_data();
    }, []);

    useEffect(() => {
        // return () => {
        setData2Charts();
        // }
    }, [resultClosePrice, resultRubber]);

    ///////////////////// Chart ////////////
    function syncCrosshair(chart, series, dataPoint) {
        if (dataPoint) {
            chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
            return;
        }
        chart.clearCrosshairPosition();
    }

    function getCrosshairDataPoint(series, param) {
        if (!param.time) {
            return null;
        }
        const dataPoint = param.seriesData.get(series);
        return dataPoint || null;
    }
    /////////////////////////////////////////

    // const symbols = ['AAPL', 'GOOGL', 'MSFT', 'AMZN', 'FB'];['7UP', 'PTT', 'PTTCG']
    const [symbols, setSymbols] = useState([]);
    useEffect(() => {
        const getSymbolList = async () => {
            const response = await __fetching_data__({ url: `${base_api_url}/symbol_list`, method: "GET" });
            if (response.status) {
                const uniqueSymbols = Array.from(new Set(response.data.map(val => val.SymbolName)));
                setSymbols(uniqueSymbols);
            }
        }
        getSymbolList();
    }, []);

    const resChartRef = useRef(null);
    const resToolsRef = useRef(null);
    const [gridHeight, setGridHeight] = useState(0);
    useEffect(() => {
        if (!resChartRef.current) return;
        const height = resChartRef.current.getBoundingClientRect().height;
        setGridHeight(height);

        const remainingHeight = `calc(100vh - ${95}px)`;
        resChartRef.current.style.height = remainingHeight;
    }, []);

    return (
        <Box m="20px" sx={{
            // position: 'absolute',
            // backgroundColor: 'lavender',
            // width: '100%',
            // height: '100%'
        }}>
            <Preloader open={loading} />
            {/* <Box display="flex" justifyContent="space-between" alignItems="center"> */}
            {/* <Header title="Chart Petroleum" subtitle="" /> */}

            {/* <Box>
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                    >
                        <DownloadOutlinedIcon sx={{ mr: "10px" }} />
                        Download Reports
                    </Button>
                </Box> */}
            {/* </Box> */}

            <Box display="block"
                sx={{
                    //Start Rubber page
                    position: 'relative',
                    padding: 0,
                    margin: 0,
                    width: '100%',
                    height: '100%',
                    // backgroundColor: 'lavender',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'


                }}>
                <Grid container spacing={2}
                    sx={{
                        position: 'absolute',
                        top: {
                            xs: '3%',
                            sm: '4.2%',
                            md: '5%',
                            lg: '7%'
                        },
                        marginBottom: "1rem",
                        width: '100%',
                        //backgroundColor: 'burlywood',

                    }}
                    ref={resToolsRef}
                >
                    <Box sx={{
                        position: 'relative',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',

                        //backgroundColor: 'yellow',
                    }}>
                        <Box sx={{
                            position: 'absulute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',

                            width: '25%'

                        }}>
                            <Autocomplete
                                disablePortal
                                id="symbol"
                                options={symbols}
                                // autoComplete
                                autoHighlight
                                fullWidth
                                onChange={(event, newValue) => {
                                    setSelectedSymbol(newValue);
                                    setParamsQuery((prevData) => ({
                                        ...prevData,
                                        'symbol': newValue,
                                        'queryType': newValue ? 2 : 1,
                                    }));
                                    if (newValue) {
                                        get_data(newValue);
                                    }
                                }}
                                value={selectedSymbol}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Symbol"
                                        name="symbol"
                                        variant="outlined"
                                        required
                                        size="small"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                                renderOption={(props, option) => (
                                    <MenuItem {...props}>
                                        <Typography variant="inherit">{option}</Typography>
                                    </MenuItem>
                                )}
                            />

                            <Grid item xs={12} md={6}>
                                {/* <Button variant="contained"
                                    sx={{
                                        backgroundColor: colors.blueAccent[500],
                                        marginLeft: '1rem'

                                    }}
                                    type="button"
                                    onClick={handleSearchSymbol}
                                >
                                    Search
                                </Button> */}


                            </Grid>
                        </Box>

                    </Box>

                    <Grid item xs={4} md={2}></Grid>
                    <Grid item xs={4} md={2}></Grid>
                </Grid>
                <Grid
                    ref={resChartRef}
                    container
                    spacing={0}
                // bgcolor="red"


                >

                    <Grid item xs={12} md={12} lg={12}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'right', // Align content to the right

                            // backgroundColor: 'yellow'
                        }}>

                        <div id="chart-close" ref={(element) => handleAddChartContainer(element)} style={{
                            position: 'relative',
                            width: '95%',
                            height: '90%',
                            bottom: '-20%'
                        }}></div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'right', // Align content to the right
                            bottom: '0%'
                        }}>
                        <div id="chart-rubber-raw" ref={(element) => handleAddChartContainer(element)} style={{
                            position: 'relative',
                            width: '95%',
                            height: '90%',
                            bottom: '-15%'
                        }}></div>
                    </Grid>
                </Grid>

            </Box>
        </Box >


    )

}
export default ThaiRubberChartComponent;