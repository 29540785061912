
let rank = 10;

export function setRank(value) {
    rank = value;
    document.getElementById('dropdownMenuButton').textContent = `Rank ${rank}`;

}

const loadingGif = document.createElement('img');
// const rangeShow = document.getElementById('range-show');
loadingGif.src = '/static/assets/loading.gif';
loadingGif.width = 50;
loadingGif.height = 50;
export function topRank(rank) {
    const tableArea = document.getElementById('table-area');
    tableArea.innerHTML = '';
    tableArea.appendChild(loadingGif);

    const start_date = document.getElementById('start-date').value;
    const end_date = document.getElementById('end-date').value;

    fetch(
        'http://172.18.1.65:5040/toprank',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "rank": rank, "start_date": start_date, "end_date": end_date }),
        }
    ).then(
        response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        }
    ).then(
        data => {
            tableArea.removeChild(loadingGif);
            tableArea.innerHTML = data['toprankData'];

            initializeDataTable(tableArea);


            const min_date = data['min_date'];
            const max_date = data['max_date'];
            const dateRange = document.createElement('div');
            dateRange.classList.add('d-flex', 'flex-row')
            const topic = document.createElement('p');
            topic.textContent = `DATE RANGE: `;
            topic.style.fontWeight = 'bold';
            dateRange.appendChild(topic);
            const minDate = document.createElement('p');
            minDate.textContent = ` ${min_date} - ${max_date} `;
            dateRange.appendChild(minDate);



            const annonation = document.getElementById('annotation');
            annonation.innerHTML = ''
            const topAnnotation = document.createElement('p');
            topAnnotation.textContent = `TOP ${rank} RANK`;
            topAnnotation.style.fontWeight = 'bold';
            topAnnotation.style.fontSize = '1vw';
            annonation.appendChild(topAnnotation);
            annonation.appendChild(dateRange);



        }
    ).catch(
        error => {
            console.error('There has been a problem with your fetch operation:', error);
        }
    );
}

function initializeDataTable(container) {
    const dataTable = container.querySelector('table');
    if (dataTable) {
        if (!$.fn.DataTable.isDataTable(dataTable)) {
            $(dataTable).DataTable({
                'order': [[0, 'desc']], // Sort by first column in descending order
                'autoWidth': true
            });
        }
    }
}