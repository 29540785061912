import { useState, useEffect, useMemo, useCallback } from "react";
import { Routes, Route, Navigate, Outlet, BrowserRouter, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";

import { Box, CssBaseline, ThemeProvider, Typography, IconButton, Drawer, List, ListItem, ListItemText } from "@mui/material";
import { Css, Height, WidthFull } from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import LoginPage from "./scenes/auth/login";
import LogoutPage from "./scenes/auth/logout";

import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import Calendar from "./scenes/calendar/calendar";

import ProfilePage from "./scenes/auth/profile";
import DejavuBidask from "./scenes/dejavubidask";
import DejavuTickmatch from "./scenes/dejavutickmatch";
import Chatbot from "./scenes/chatbot";
import Realtime from "./scenes/realtime/realtime";
import RankBoard from './scenes/valueoftime/pages/rankboard'
import TopRank from './scenes/valueoftime/pages/toprank'
import Petroleum from "./scenes/petroleum";
import PetroleumComponent from "./scenes/test_petroleum";
import UsersComponent from "./scenes/users";
import RolesComponent from "./scenes/roles";
import ThaiRubberChartComponent from "./scenes/rubber";
import BrokerrankingComponent from "./scenes/brokerranking/brokerranking"
import LastedBrokerrankingComponent from "./scenes/brokerranking/newBrokerranking"
import Gold from './scenes/gold/gold'

import Error401Page from "./scenes/error/401";
import Error403Page from "./scenes/error/403";
import UpgradePlanPage from "./scenes/error/upgrade_plan";

import { ColorModeContext, useMode } from "./theme";
import { setCsrfToken, setLogin, setUserDetail, setIpAddress } from "./state";
import { useFetchingData, __fetchIp__ } from './components/api';
import { useIsMobile } from './components/hooks/'

function App() {
  const [theme, colorMode] = useMode();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { __fetching_data__ } = useFetchingData();

  const [isSidebar, setIsSidebar] = useState(true);

  const isAuth = Boolean(useSelector((state) => state.token != null));
  const userId = useSelector((state) => state.user);
  const userToken = useSelector((state) => state.token);
  const csrfToken = useSelector((state) => state.csrfToken);
  const userDetail = useSelector((state) => state.userDetail);
  const ipAddress = useSelector((state) => state.ipAddress);
  const [isOnline, setIsOnline] = useState(navigator.onLine);


  const check_kickoff = async () => {
    const response = await __fetching_data__({ url: `${window.config.base_api_url}/check_kickoff`, method: "POST" });
  }

  const getCSRF = async () => {
    const response = await __fetching_data__({ url: `${window.config.base_api_url}/getCSRF`, method: "GET" });
    dispatch(setCsrfToken({ csrfToken: response.csrfToken }));
  };

  const fetchUser = async () => {
    const userData = await __fetching_data__({ url: `${window.config.base_api_url}/userprofile1/${userId}`, headers: { "Authorization": `Bearer ${userToken}` } });
    // console.log(userData)
    if (userData.status) {
      dispatch(setUserDetail({
        Id: userId,
        img_src: userData.data.img_src ? userData.data.img_src : "",
        fullname: (userData.data.firstname ? userData.data.firstname : "") + " " + (userData.data.lastname ? userData.data.lastname : ""),
        firstname: userData.data.firstname ? userData.data.firstname : "",
        lastname: userData.data.lastname ? userData.data.lastname : "",
        email: userData.data.email ? userData.data.email : "",
        app_id: userData.data.app_id ? userData.data.app_id : "",
        user_type: userData.data.user_type ? userData.data.user_type : "",
        permissions: userData.data.permissions ? userData.data.permissions : ""
      }));
    }
  }

  const fetchIp = async () => {
    const IP = await __fetchIp__();
    if (IP.ip) {
      dispatch(setIpAddress({
        ipAddress: IP.ip
      }))
    }
  }


  const handleOnline = () => {
    console.log('Client is online, perform your action here.');
  };

  const updateOnlineStatus = () => {
    const onlineStatus = navigator.onLine;
    if (onlineStatus) {
      handleOnline();
    }
    setIsOnline(onlineStatus);
  };


  useEffect(() => {
    return () => {
      if (csrfToken === null) { getCSRF(); }
    }
  }, [csrfToken]);

  useEffect(() => {
    console.log('IsMobile :', isMobile);

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    if (!ipAddress) {
      fetchIp();
    }
    return () => {
      if (isAuth) {
        fetchUser();
      }

      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  useEffect(() => {
    if (!Cookies.get("access_token") && !Cookies.get("refresh_token")) {
      // dispatch(setLogin({ user: null, token: null }));
    }
    if (isAuth && (!userDetail.email || userDetail.email == null || userDetail.email == undefined)) {
      fetchUser();
    }
  }, [isAuth, userDetail, userId]);

  const RouteChangeHandler = () => {
    const location = useLocation();
    useEffect(() => {
      const handleRouteChange = () => {
        const isAuthRoute = !['/login', '/logout'].includes(location.pathname)
        if (isAuth) {
          isAuthRoute && check_kickoff();
          isAuthRoute && !ipAddress && fetchIp();
        }
      };

      return () => {
        handleRouteChange();
      }
    }, [location]);
    return null;
  };

  const Protected = ({ children }) => {
    if (!isAuth) {
      return <Navigate to="/login" replace />;
    }
    return <Outlet />;
  };

  const protectRoute = (Component, requiredPermission) => {
    if (hasPermission(userDetail.permissions, requiredPermission)) {
      return <Component />;
    } else {
      return <Navigate to="/403" replace />;
    }
  };

  const hasPermission = (permissions, requiredPermission) => {
    if (!permissions || !permissions.permission || !permissions.permission[requiredPermission]) {
      return false;
    }
    const permission = permissions.permission[requiredPermission];
    return permission && permission.read;
  };

  const [ws, setWs] = useState(null);
  const [message, setMessage] = useState('');
  const [receivedMessage, setReceivedMessage] = useState('');
  const [messageType, setMessageType] = useState('data');

  useEffect(() => {
    let ws_url;
    ws_url = 'wss://ideatrade1.com:4002/data';
    // ws_url = '/application/data'
    //`ws://${window.location.host}/application`

    // const socket = new WebSocket(ws_url);

    // socket.onopen = () => {
    //   console.log('WebSocket connection established');
    //   const message = {
    //     token: userToken
    //   };
    //   socket.send(JSON.stringify(message));
    // };

    // socket.onmessage = (event) => {
    //   console.log('Received message:', event.data);
    //   //   const parsedMessage = JSON.parse(event.data);
    //   //   console.log(parsedMessage)
    //   //   // switch (parsedMessage.type) {
    //   //   //   case 'echo':
    //   //   //     setReceivedMessage(`Echo: ${parsedMessage.data}`);
    //   //   //     break;
    //   //   //   case 'broadcast':
    //   //   //     setReceivedMessage(`Broadcast: ${parsedMessage.data}`);
    //   //   //     break;
    //   //   //   default:
    //   //   //     setReceivedMessage('Unknown message type');
    //   //   // }
    // };

    // socket.onclose = () => {
    //   console.log('WebSocket connection closed');
    // };

    // setWs(socket);

    // return () => {
    //   socket.close();
    // };
  }, []);

  const [open, setOpen] = useState(false);
  const toggleSidebar = () => setOpen(!open);

  return (
    <BrowserRouter>
      <RouteChangeHandler />
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box>
            <div className="app">

              {isAuth && !isMobile && <Sidebar isSidebar={isSidebar} />}


              <main className="content" id="content">
                <Box>
                  {isMobile && (
                    <>
                      <IconButton onClick={toggleSidebar} color="primary">
                        <MenuIcon />
                      </IconButton>
                      <Drawer
                        anchor="left"
                        open={open}
                        onClose={toggleSidebar}
                        variant="temporary"
                        sx={{ width: 240, flexShrink: 0 }}
                      >
                        <Box sx={{ width: 240, height: '100%', display: 'flex', flexDirection: 'column' }}>
                          <IconButton onClick={toggleSidebar} color="primary" sx={{ alignSelf: 'flex-end', mt: 2, mr: 2 }}>
                            <CloseIcon />
                          </IconButton>
                          <List sx={{ flexGrow: 1, pt: 0 }}>
                            <ListItem button>
                              <ListItemText primary="Item 1" />
                            </ListItem>
                            <ListItem button>
                              <ListItemText primary="Item 2" />
                            </ListItem>
                            {/* Add more items here */}
                          </List>
                        </Box>
                      </Drawer>
                    </>
                  )}
                  {/* {isAuth && <Topbar setIsSidebar={setIsSidebar} />} */}
                  <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/logout" element={<LogoutPage />} />
                    <Route path="/401" element={<Error401Page />} />
                    <Route path="/403" element={<Error403Page />} />
                    <Route element={<Protected />} >
                      {/* <Route path="/" element={<Box />} /> */}
                      <Route path="/" element={
                        <Box textAlign="center" mt={5}>
                          <Typography variant="h4" color={'white'}>
                            {isOnline ? 'You are online' : 'You are offline'}
                          </Typography>
                        </Box>
                      } />
                      <Route path="/dashboardp" element={<Dashboard />} />
                      <Route path="/team" element={<Team />} />
                      <Route path="/contacts" element={<Contacts />} />
                      <Route path="/invoices" element={<Invoices />} />
                      <Route path="/form" element={<Form />} />
                      <Route path="/bar" element={<Bar />} />
                      <Route path="/pie" element={<Pie />} />
                      <Route path="/line" element={<Line />} />
                      <Route path="/faq" element={<FAQ />} />
                      <Route path="/calendar" element={<Calendar />} />
                      <Route path="/geography" element={<Geography />} />

                      <Route path="/chatbot" element={<Chatbot />} replace />
                      <Route path="/petroleum" element={<PetroleumComponent />} />
                      <Route path="/rubber" element={<ThaiRubberChartComponent />} />
                      <Route path="/realtime" element={<Realtime />} />
                      <Route path="/gold" element={<Gold />} />
                      <Route path="/dejavu/bidask" element={<DejavuBidask />} />
                      <Route path="/dejavu/tickmatch" element={<DejavuTickmatch />} />
                      {/* <Route path="/rankboard" element={<RankBoard />} /> */}
                      {/* <Route path="/toprank" element={<TopRank />} /> */}

                      <Route path="/brokervalue" element={<LastedBrokerrankingComponent />} />
                      <Route path="/profile" element={<ProfilePage />} />

                      <Route path="/upgrade" element={<UpgradePlanPage />} />
                      <Route path="/admin/users" element={protectRoute(UsersComponent, 'manageUser')} />
                      <Route path="/admin/roles" element={protectRoute(RolesComponent, 'manageRole')} />
                    </Route>
                  </Routes>
                </Box>
              </main>
            </div>
          </Box>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </BrowserRouter >
  );
}

export default App;
