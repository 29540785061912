import { createChart } from 'lightweight-charts';

const loadingGif = document.createElement('img');
// const rangeShow = document.getElementById('range-show');
loadingGif.src = '/static/assets/loading.gif';
loadingGif.width = 50;
loadingGif.height = 50;

const chartContainer = document.createElement('chart-container');
const rankChart = createChart(chartContainer);




export function renderRankTable() {
    const loadingGifContainer = document.getElementById('loading-gif-container-table');

    const startDate = document.getElementById('start-date').value;
    const endDate = document.getElementById('end-date').value;


    if (loadingGifContainer !== null && loadingGifContainer.innerHTML !== '') {
        loadingGifContainer.innerHTML = '';
    }

    loadingGifContainer.appendChild(loadingGif);
    fetch('http://172.18.1.65:5040/rank-data', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "start_date": startDate, "end_date": endDate, 'process_id': 1 }),
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            // console.log(data);
            if (loadingGifContainer.innerHTML !== '') {
                loadingGifContainer.innerHTML = '';
            }
            if (document.getElementById('range-show') !== null) {
                document.getElementById('range-show').remove();
            }
            const rankData = data['rankData'];
            const table = getTable(rankData);
            const tableContainer = document.getElementById('table-container');
            tableContainer.innerHTML = '';


            /* ----------------------------- Add show range ----------------------------- */

            const showRange = document.createElement('div');
            showRange.textContent = `Showing data from ${data['min_date']} to ${data['max_date']}`;
            showRange.setAttribute('id', 'range-show');
            showRange.classList.add('text-start', 'mt-2');
            showRange.style.fontWeight = 'bold';
            tableContainer.appendChild(showRange);

            
            tableContainer.appendChild(table);
        })
        .catch(error => {
            console.error('Fetch error:', error);
            if (loadingGifContainer.innerHTML !== '') {

                loadingGifContainer.innerHTML = '';
            }
            loadingGifContainer.innerHTML = '<p class="text-danger">Error fetching data. Please try again later.</p>';
        });
}

function getTable(data) {
    const tableContainer = document.createElement('div');
    tableContainer.classList.add('table-container');
    tableContainer.classList.add('border', 'border-2', 'm-2');
    tableContainer.setAttribute("id", "table-show");
    tableContainer.innerHTML = data;

    initializeDataTable(tableContainer);
    return tableContainer;

}

/* ------------------------------ Make a table ------------------------------ */
function initializeDataTable(container) {
    const dataTable = container.querySelector('table');
    if (dataTable) {
        // Initialize DataTable if not already initialized
        if (!$(dataTable).DataTable()) {
            $(dataTable).DataTable({
                'index': true,
                "autoWidth": true
            });
        }
    }
}

/* ---------------------------------- Chart --------------------------------- */
let seriesMap = new Map();

export function renderRankChart() {
    const loadingGifContainer = document.getElementById('loading-gif-container-chart');

    const chart = rankChart;
    const modalBody = document.getElementById('modal-body')

    chart.subscribeClick(function (param) {
        document.getElementById('myModal').style.display = 'block';

    });
    chart.applyOptions({
        rightPriceScale: {
            invertScale: true,
        },
    });

    const startDate = document.getElementById('start-date').value;
    const endDate = document.getElementById('end-date').value;
    if (loadingGifContainer !== null && loadingGifContainer.innerHTML !== '') {
        loadingGifContainer.innerHTML = '';
    }
    const symbol = document.getElementById('symbol').value;
    loadingGifContainer.appendChild(loadingGif);
    const listofcolors = [];
    fetch('http://172.18.1.65:5040/rank-data', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "start_date": startDate, "end_date": endDate, 'process_id': 2, 'symbol': symbol }),
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            if (loadingGifContainer.innerHTML !== '') {
                loadingGifContainer.innerHTML = '';
            }
            const plotData = data['plotData'];
            const chartData = generateData(plotData, 'time', 'ranked');
            let color = generateRandomColor(listofcolors);
            listofcolors.push(color);
            const chartSeries = chart.addLineSeries({
                name: symbol,
                color: color,
                lineWidth: 2,
                marker: {
                    enabled: false
                }
            });
            chartSeries.setData(chartData);
            seriesMap.set(symbol, chartSeries);
            /* --------------------------------- Legend --------------------------------- */
            const legendItem = document.createElement('div');
            legendItem.classList.add('legend-item');
            legendItem.setAttribute('id', symbol);
            legendItem.classList.add('d-flex', 'flex-row', 'justify-content-between', 'legend-row', 'align-items-center')
            legendItem.style.alignItems = 'center';
            const legendText = document.createElement('p');
            legendText.classList.add('legend-text', 'ms-2', 'align-self-center', 'text-center');
            legendText.style.textAlign = 'center';
            legendText.style.margin = 'auto';
            legendText.textContent = symbol;



            const colorBox = document.createElement('div');
            colorBox.addEventListener('click', hideSeriesByName(symbol));
            colorBox.classList.add('color-box', 'rounded-circle', 'ms-2');
            colorBox.style.backgroundColor = color;
            colorBox.style.width = '20px';
            colorBox.style.height = '20px';
            colorBox.style.backgroundColor = color;

            legendItem.appendChild(colorBox);
            legendItem.appendChild(legendText);

            const legendArea = document.getElementById('legend');
            legendArea.appendChild(legendItem);

            /* -------------------------------- Modal Row ------------------------------- */
            const modalRow = document.createElement('div');
            modalRow.setAttribute('id', symbol);
            modalRow.classList.add('d-flex', 'flex-row', 'justify-content-between', 'align-items-center', 'modal-row', symbol);
            modalRow.style.margin = '10px';
            modalRow.style.padding = '10px';
            modalRow.style.border = '1px solid #000';

            const clonedLegendItem = legendItem.cloneNode(true);
            modalRow.appendChild(clonedLegendItem);

            const buttonContainer = document.createElement('div');
            const removeButton = document.createElement('button');
            removeButton.classList.add('btn', 'btn-sm', 'btn-danger', 'remove-button');
            removeButton.textContent = 'Remove';
            removeButton.addEventListener('click', removeSeriesByName(symbol));

            const hideButton = document.createElement('button');
            hideButton.setAttribute('id', symbol);
            hideButton.classList.add('btn', 'btn-sm', 'btn-secondary', 'hide-button','me-2');

            hideButton.textContent = 'Hide';
            hideButton.addEventListener('click', hideSeriesByName(symbol));

            buttonContainer.appendChild(hideButton);
            buttonContainer.appendChild(removeButton);
            modalRow.appendChild(buttonContainer);

            chart.timeScale().fitContent();
            document.getElementById('symbol').value = '';

            modalBody.appendChild(modalRow);

        })
        .catch(error => {
            console.error('Fetch error:', error);
            if (loadingGifContainer.innerHTML !== '') {
                loadingGifContainer.innerHTML = '';
            }
            loadingGifContainer.innerHTML = '<p class="text-danger">Error fetching data. Please try again later.</p>';
        });
    function generateRandomColor(listofcolors) {
        const letters = '0123456789ABCDEF';
        let color = '#';
        while (true) {
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            if (!listofcolors.includes(color)) {
                return color;
            }
        }
    }

    function hideSeriesByName(seriesName) {
        return function () {
            const series = seriesMap.get(seriesName);
            if (series) {
                series.applyOptions({
                    visible: !series.options().visible
                });
                const colorBox = document.querySelector(`#${seriesName} .color-box`);
                if (colorBox) {
                    colorBox.style.width = series.options().visible ? '20px' : '10px';
                    colorBox.style.height = series.options().visible ? '20px' : '10px';
                }
                const hideButton = document.getElementsByClassName('hide-button');
                for (let i = 0; i < hideButton.length; i++) {
                    if (hideButton[i].id === seriesName) {
                        hideButton[i].textContent = series.options().visible ? 'Hide' : 'Show';
                    }
                }
            }
        }

    }
    function removeSeriesByName(symbol) {
        return function () {
            const series = seriesMap.get(symbol);
            if (series) {
                seriesMap.delete(symbol);
                chart.removeSeries(series);

                const legendItem = document.getElementById(symbol);
                if (legendItem) {
                    legendItem.remove();
                }
                const modalRow = document.getElementById(symbol);
                if (modalRow) {
                    modalRow.remove();
                }

            }
        }
    }
}