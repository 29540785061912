import { useState } from "react";
import {
    Typography,
    TextField,
    Button,
    Container,
    useTheme,
    useMediaQuery,
    MenuItem,
    InputAdornment,
    OutlinedInput,
    Autocomplete,
    Select,
    FormControl,
    InputLabel,
    Grid,
    Paper,
    Box,
    FormGroup,
    List,
    ListItem,
    ListItemText,
    Popover,
    Menu,
    Tab,
    Tabs,
} from "@mui/material";
import LinearProgress, {
    linearProgressClasses,
} from "@mui/material/LinearProgress";
import { __fetching_data__ } from "../../../components/api";
import { useSelector, useDispatch } from "react-redux";

const TabPassword = ({ userDetail, colors, isMobile }) => {
    const [isEditPassword, setIsEditPassword] = useState(false);
    const [localUserDetail, setLocalUserDetail] = useState(userDetail);
    const [pwdQuality, setPwdQuality] = useState({
        label: "Very Weak",
        percent: 0,
        color: null,
        match: "no"
    });
    const pwdQualityProps = {
        label: ["Very Weak", "Weak", "Fair", "Strong", "Very Strong"],
        color: [
            colors.redAccent[500],
            colors.redAccent[500],
            colors.yellowAccent[300],
            colors.yellowAccent[300],
            colors.greenAccent[600],
        ],
    };
    const getPasswordStrength = (password) => {
        const lengthRule = password.length >= 8;
        const containsUppercase = /[A-Z]/.test(password);
        const containsLowercase = /[a-z]/.test(password);
        const containsDigit = /\d/.test(password);
        const containsSpecialChar = /[$@#&!]+/.test(password);
        const score = [
            containsUppercase,
            containsLowercase,
            containsDigit,
            containsSpecialChar,
        ].filter(Boolean).length;
        const percentage = (score / 4) * 100;
        return {
            label: pwdQualityProps["label"][score],
            percent: percentage,
            color: pwdQualityProps["color"][score],
        };
    };
    const handleEditPassword = (e) => {
        const { name, value } = e.target;
        if (name == "edit_password") {
            setIsEditPassword(true);
        } else {
            setIsEditPassword(!isEditPassword);
        }
        if (name == "cancel_edit_password") {
            ["current_password", "new_password", "confirm_password"].forEach((name) =>
                setLocalUserDetail((prevData) => ({ ...prevData, [name]: "" }))
            );
        }
    };
    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setLocalUserDetail((prevData) => ({ ...prevData, [name]: value }));
    };
    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setLocalUserDetail((prevData) => ({ ...prevData, [name]: value }));
        const passwordStrength = getPasswordStrength(value);
        if (name === 'new_password') {
            passwordStrength.match = 'Yes';
            setPwdQuality(passwordStrength);

        }
        if (name === 'new_password' && value === localUserDetail.confirm_password) {
            setPwdQuality((prevData) => ({ ...prevData, match: "yes" }));
        } else if (name === 'confirm_password' && value === localUserDetail.new_password) {
            setPwdQuality((prevData) => ({ ...prevData, match: "yes" }));
        } else {
            setPwdQuality((prevData) => ({ ...prevData, match: "no" }));
        }
    };
    const csrfToken = useSelector((state) => state.csrfToken);
    const base_api_url =
        process.env.NODE_ENV === "production"
            ? window.location.protocol === "https:"
                ? process.env.REACT_APP_API_HTTPS_URL
                : process.env.REACT_APP_API_HTTP_URL
            : "/application";


    const handleSavePassword = async (e) => {
        // const { name, value } = e.target;
        // console.log(localUserDetail);
        // console.log(pwdQuality);
        if (pwdQuality.match == "yes" && pwdQuality.percent > 50) {
            const data = {
                current_password: localUserDetail.current_password,
                new_password: localUserDetail.new_password,
                confirm_password: localUserDetail.confirm_password
            }
            // const url = `/application/changepassword`;
            
            const response = await __fetching_data__({
                url: `${base_api_url}/changepassword`,
                method: "PUT",
                headers: { "xsrf-token": csrfToken },
                body: JSON.stringify(data)
            })
            // console.log(response)
        } else {
            // console.log("Not Change")
        }

    };

    return (
        <Grid container>
            <Grid container spacing={2}>
                <Grid
                    container
                    spacing={1}
                    columns={{ md: 12 }}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{
                        marginTop: 1,
                    }}
                >
                    {!isEditPassword ? (
                        <Button
                            name="edit_password"
                            variant="contained"
                            type="button"
                            size={isMobile ? "small" : "medium"}
                            sx={{
                                marginLeft: 1,
                                textTransform: "none",
                                backgroundColor: colors.blueAccent[400],
                                "&:hover": {
                                    backgroundColor: colors.blueAccent[400],
                                },
                            }}
                            onClick={(e) => handleEditPassword(e)}
                        >
                            Change Password
                        </Button>
                    ) : (
                        <Button
                            name="save"
                            variant="contained"
                            type="submit"
                            size={isMobile ? "small" : "medium"}
                            sx={{
                                marginLeft: 1,
                                textTransform: "none",
                                backgroundColor: colors.greenAccent[500],
                                "&:hover": {
                                    backgroundColor: colors.greenAccent[500],
                                },
                            }}
                            onClick={(e) => handleSavePassword(e)}
                            disabled={localUserDetail.new_password !== localUserDetail.confirm_password}
                        >
                            Save
                        </Button>
                    )}
                    {isEditPassword && (
                        <Button
                            name="cancel_edit_password"
                            variant="contained"
                            type="submit"
                            size={isMobile ? "small" : "medium"}
                            sx={{
                                marginLeft: 1,
                                textTransform: "none",
                                backgroundColor: colors.redAccent[500],
                                "&:hover": {
                                    backgroundColor: colors.redAccent[500],
                                },
                            }}
                            onClick={(e) => handleEditPassword(e)}
                        >
                            Cancel
                        </Button>
                    )}
                </Grid>
            </Grid>
            {/** row ================================================================================================================================== */}
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        type="password"
                        label="Current Password"
                        name="current_password"
                        variant={!isEditPassword ? "standard" : "outlined"}
                        margin="normal"
                        value={localUserDetail?.current_password}
                        onChange={handleChangeInput}
                        size="small"
                        InputProps={{
                            readOnly: !isEditPassword,
                        }}
                    />
                </Grid>
            </Grid>
            {/** row ================================================================================================================================== */}
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        type="password"
                        label="Password"
                        name="new_password"
                        variant={!isEditPassword ? "standard" : "outlined"}
                        margin="normal"
                        value={localUserDetail?.new_password}
                        onChange={handlePasswordChange}
                        size="small"
                        InputProps={{
                            readOnly: !isEditPassword,
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Grid container columns={{ xs: 12, md: 12 }} sx={{ marginTop: 3 }}>
                        <Grid item xs={6} md={10}>
                            <Typography>
                                Password quality:{" "}
                                <Typography
                                    component="span"
                                    variant="h5"
                                    sx={{
                                        fontWeight: "bold",
                                        color: pwdQuality.color
                                    }}
                                >
                                    {pwdQuality.label}
                                </Typography>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/** row ================================================================================================================================== */}

            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        type="password"
                        label="Confirm Password"
                        name="confirm_password"
                        variant={!isEditPassword ? "standard" : "outlined"}
                        margin="normal"
                        value={localUserDetail.confirm_password}
                        onChange={handlePasswordChange}
                        size="small"
                        InputProps={{
                            readOnly: !isEditPassword,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Grid container columns={{ xs: 12, md: 12 }} sx={{ marginTop: 3 }}>
                        <Grid item xs={6} md={6}>
                            <Typography>Password match:{" "}
                                <Typography
                                    component="span"
                                    variant="h5"
                                    sx={{
                                        fontWeight: "bold",
                                        // color: pwdQuality.color
                                    }}
                                >
                                    {pwdQuality.match}
                                </Typography></Typography>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>



        </Grid>
    );
};

export default TabPassword;
