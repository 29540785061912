import { useState, useEffect, forwardRef, createRef, useContext, useRef } from "react";
import {
    Box, Button, TextField, useMediaQuery, Typography, useTheme, Grid, Select, MenuItem, FormControl, InputLabel, Chip, OutlinedInput, FormLabel, FormGroup,
    FormControlLabel, Checkbox, Card, CardContent, CardActions, RadioGroup, Radio, InputAdornment, IconButton, Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle, Slide, Backdrop, CircularProgress, Alert, AlertTitle
} from "@mui/material";
import { styled } from '@mui/material/styles';
import {
    Visibility, VisibilityOff,
    EditOutlined as EditOutlinedIcon,
    CloudUpload as CloudUploadIcon
} from '@mui/icons-material';

import { Formik } from "formik";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import ReCAPTCHA from "react-google-recaptcha";

import { ColorModeContext, tokens } from "../../../theme";
import { setLogin, setCsrfToken, setMode } from "../../../state";
import FlexBetween from "../../../components/FlexBetween";
import { useFetchingData } from '../../../components/api.js';
import KbankIcon from '../../../images/kbank_icon.png';
import "./form.css";

const registerSchema = yup.object().shape({
    firstname: yup.string().required("required"),
    lastname: yup.string().required("required"),
    email: yup.string().email("invalid email").required("required"),
    password: yup.string().required("required"),
    packages: yup.array().min(1, "Select at least one package").notRequired(),
    plan: yup.string().required("required"),
    slip_image: yup.string().required("required"),
});
// password: yup.string().required("required"),
//picture: yup.string().required("required"),

const loginSchema = yup.object().shape({
    email: yup.string().email("invalid email").required("required"),
    password: yup.string().required("required"),
});

const initialValuesRegister = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    picture: "",
    packages: [],
    total_price: 0,
    slip_image: null,
    plan: "monthly"
};

const initialValuesLogin = {
    email: "",
    password: "",
};

const forgetPassword = () => {
    //alert('Your ID is : your email address\nYour Password is : your phone number');
    <Dialog>

    </Dialog>
};

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const parseJwt = (token) => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
}

const Form = () => {
    // const classes = useOTPStyles();
    const { __fetching_data__ } = useFetchingData();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    // const colors = colorTokens(theme.palette.mode);
    const colors = tokens(theme.palette.mode);
    const { palette } = useTheme();
    const colorMode = useContext(ColorModeContext);

    const [pageType, setPageType] = useState("login");
    const isLogin = pageType === "login";
    const isRegister = pageType === "register";
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const google = window.google ? window.google : {};

    const [open, setOpen] = useState(false);
    const [openSignUp, setOpenSignUp] = useState(false);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [openDialogOtp, setOpenDialogOtp] = useState(false);
    const [dialogValue, setDialogValue] = useState(null);
    const [user, setUser] = useState(null);

    const csrfToken = useSelector((state) => state.csrfToken);
    const [fileName, setFileName] = useState('Upload Slip');

    const recaptchaRef = createRef();
    const [recaptchaValue, setRecaptchaValue] = useState(null);

    const handleReCaptchaOnChange = async (value) => {
        setRecaptchaValue(value);
        // var url =
        //     "https://www.google.com/recaptcha/api/siteverify?secret=6Lc7WYwjAAAAAFSA7L9up4GG0gwIHI7M4Os7WAub&response=" +
        //     value;
    };

    const AlertMessage = ({ severity, title, message }) => {
        return (
            <Alert severity={severity} variant="filled">
                {title && <AlertTitle>{title}</AlertTitle>}
                {message}
            </Alert>
        );
    };

    const forgetPasswordClick = () => {
        setOpen(true);
    };
    const forgetPasswordClose = () => {
        setOpen(false);
    };
    const signUpClick = () => {
        setOpenSignUp(true);
        setPageType(isLogin ? "register" : "login");
    };
    const signUpClose = () => {
        setOpenSignUp(false);
        setPageType(isLogin ? "register" : "login");
    };

    const handleOpenBackdrop = () => {
        setOpenBackdrop(true);
    };
    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    };
    const handleToggleBackdrop = () => {
        setOpenBackdrop(!openBackdrop);
    };
    const [isRegisterStatus, setRegisterStatus] = useState(false);

    const [packages, setPackage] = useState(null);
    const getPackage = async () => {
        const roleResponse = await __fetching_data__({ url: `${window.config.base_api_url}/get_package`, method: "GET", headers: { "xsrf-token": csrfToken } });
        setPackage(roleResponse.data)
    }
    useEffect(() => {
        getPackage();
    }, []);
    const handleFormSubmit = async (values, onSubmitProps) => {
        if (isLogin) await login(values, onSubmitProps);
        if (isRegister) await register(values, onSubmitProps);
    };
    const register = async (values, onSubmitProps) => {
        const formData = new FormData();
        for (let value in values) {
            if (value != "slip_image") {
                formData.append(value, values[value]);
            }
        }
        if (values.slip_image) {
            formData.append('slip_image', values.slip_image);
        }

        const registerResponse = await __fetching_data__({ url: `${window.config.base_api_url}/register`, method: "POST", headers: { "xsrf-token": csrfToken }, body: formData, isUploadImg: true });

        if (registerResponse.status) {
            setRegisterStatus(true);
            onSubmitProps.resetForm();
            setTimeout(() => {
                signUpClose();
                setRegisterStatus(false);
            }, 10000);
        }

    };
    const login = async (values, onSubmitProps) => {
        handleLogin(values, "local", onSubmitProps);
    };
    const loginGoogle = async (values, onSubmitProps) => {
        handleLogin(values, "google");
    };
    const handleLogin = async (values, type, onSubmitProps) => {

        setLoginStatus(false);
        var value = null;
        if (type === "local") {
            value = values;
        }
        if (type === "google") {
            var decodedGoogle = parseJwt(values.credential);
            value = {
                email: decodedGoogle.email,
                firstname: decodedGoogle.given_name,
                lastname: decodedGoogle.family_name,
                auth_is_google: true,

                aud: decodedGoogle.aud,
                azp: decodedGoogle.azp,
                email_verified: decodedGoogle.email_verified,
                exp: decodedGoogle.exp,
                iat: decodedGoogle.iat,
                iss: decodedGoogle.iss,
                jti: decodedGoogle.jit,
                locale: decodedGoogle.locale,
                fullname: decodedGoogle.name,
                nbf: decodedGoogle.nbf,
                picture: decodedGoogle.picture,
                sub: decodedGoogle.sub,
            };
        }
        handleToggleBackdrop();
        const loggedInResponse = await __fetching_data__({ url: `${window.config.base_api_url}/login`, method: "POST", headers: { "xsrf-token": csrfToken }, body: JSON.stringify(value) });
        if (loggedInResponse.status) {
            setUser({
                userId: loggedInResponse.userId,
                token: loggedInResponse.token,
                // userType: loggedInResponse.user.user_type,
            });
            handleOpenDialogOtp();

        } else {
            setLoginStatus(true);
            setLoginStatusText(loggedInResponse.message);
        } if (!loggedInResponse.status && loggedInResponse.code === 403) {
        }
        handleCloseBackdrop();
        setOtp(new Array(6).fill(''));
    };
    const handleOpenDialogOtp = () => {
        setOpenDialogOtp(true);
        setTimeout(() => {
            inputRefs.current[0]?.focus();
        }, 100);
    };

    const handleCloseDialogOtp = () => {
        setOpenDialogOtp(false);
        setOtpVerify(null);
    };

    const [otpMessage, setOtpMessage] = useState("");
    const [otpVerify, setOtpVerify] = useState(null);
    const handleSubmitOTP = async (e) => {
        var value = {
            userId: user.userId,
            otp: otp.join(''),
            type: "VERIFICATION",
        };
        const otpResponse = await __fetching_data__({ url: `${window.config.base_api_url}/email/otp`, method: "POST", headers: { "xsrf-token": csrfToken }, body: JSON.stringify(value) });
        // if (recaptchaValue) {
        if (otpResponse.status) {
            dispatch(
                setLogin({
                    user: user.userId,
                    token: user.token,
                    // userType: user.user_type,
                })
            );
            setOtpVerify(true);
            setOpenDialogOtp(false);
            navigate("/");
        } else {
            setOtpMessage("");
            setOtpVerify(false);
        }
        // }
    };

    useEffect(() => {
        if (otpVerify === false) {
            // setOtpVerify(null);
            setTimeout(() => {
                inputRefs.current[inputRefs.current.length - 1].focus();
            }, 100);
        }
    }, [otpVerify]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (window.google) {
                clearInterval(interval);
                google.accounts.id.initialize({
                    client_id:
                        "304685095232-2ptjt24p392eqi9qlr4ot6fs1g51ekje.apps.googleusercontent.com",
                    callback: loginGoogle,
                });
                google.accounts.id.renderButton(document.getElementById("signInDiv"), {
                    theme: "outline",
                    size: "large",
                });
                google.accounts.id.prompt();
            }
        }, 300);
    }, []);

    const [isLoginStatus, setLoginStatus] = useState(false);
    const [isLoginStatusText, setLoginStatusText] = useState("");

    const handleFileChange = (setFieldValue) => (event) => {
        const file = event.currentTarget.files[0];
        if (file) {
            setFieldValue("slip_image", file);
            setFileName(file.name);
        }
    };

    const updateTotalPrice = (selectedPackage, plan) => {
        var total = packages
            .filter(pack => selectedPackage.includes(pack.role_id))
            .reduce((total, pack) => total + pack.package_price[plan], 0);
        return total
    };

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => event.preventDefault();


    const [otp, setOtp] = useState(new Array(6).fill(''));
    const inputRefs = useRef([]);

    const handleChangeOTP = (element, index) => {
        if (isNaN(element.value)) return;
        setOtpVerify(null);
        // if (!/^\d$/.test(element.value)) return;
        if (element.value && !/^\d$/.test(element.value)) return;

        const newOtp = [...otp];
        newOtp[index] = element.value;

        setOtp(newOtp);
        if (element.value && index < 6 - 1) {
            inputRefs.current[index + 1]?.focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleKeyUp = (e, index) => {
        if (index == 5 && otp[index] && otp.length == 6) {
            setTimeout(() => {
                handleSubmitOTP();
            }, 100);
        }
    }

    return (

        <Formik
            key={isLogin ? 'login' : 'register'}
            onSubmit={handleFormSubmit}
            initialValues={isLogin ? initialValuesLogin : initialValuesRegister}
            validationSchema={isLogin ? loginSchema : registerSchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                        sx={{
                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                        }}
                    >
                        {isLoginStatus && (
                            <Grid item xs={12} md={12} sx={{ gridColumn: "span 4", marginTop: '-3.5rem' }}>
                                <AlertMessage severity="error" title="Login Failed" message={isLoginStatusText} />
                            </Grid>
                        )}

                        {isLogin && (
                            <>
                                <TextField
                                    label="Email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                    name="email"
                                    error={Boolean(touched.email) && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                    sx={{ gridColumn: "span 4" }}
                                />
                                <TextField
                                    label="Password"
                                    type={showPassword ? "text" : "password"}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}
                                    name="password"
                                    error={Boolean(touched.password) && Boolean(errors.password)}
                                    helperText={touched.password && errors.password}
                                    sx={{ gridColumn: "span 4" }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </>
                        )}
                    </Box>

                    {/* BUTTONS */}
                    <Box>
                        <Box
                            //Forget Password
                            position='absolute'
                            width='31.5vw'
                            height='6vh'
                            paddingLeft='0vw'
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                        >

                            {isLogin && (
                                <a href="#" style={{ color: 'black', textDecoration: 'none' }}
                                    onClick={
                                        forgetPasswordClick
                                    }

                                >Forget your ID or password?</a>
                            )}



                        </Box>

                        {isLogin && (
                            <Button
                                type="submit"
                                sx={{
                                    m: "2rem 0",
                                    p: "0.8rem",
                                    width: "46%",
                                    top: '3vh',
                                    left: "27%",
                                    backgroundColor: palette.primary.main,
                                    color: palette.primary.contrastText,
                                    "&:hover": { color: palette.primary.main },
                                }}
                            >
                                {isLogin ? "SIGN IN" : "REGISTER"}
                            </Button>
                        )}

                        <Box
                            //register
                            sx={{
                                width: '35vw',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'absolute',
                                left: '0'
                            }}
                        >
                            {isLogin && (
                                <p>Don't have an account? <a href="#" style={{ textDecoration: 'none', fontSize: '1.1rem', color: '#3083FF', fontWeight: 'bold' }} onClick={
                                    signUpClick
                                }>Sign Up</a> </p>
                            )}

                            <Dialog
                                open={openSignUp}
                                onClose={signUpClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                maxWidth={'md'}
                                fullWidth={true}
                            >


                                <DialogTitle id="alert-dialog-title" sx={{ paddingX: 10, position: 'relative' }}>
                                    <Box
                                        display="grid"
                                        gap="30px"
                                        // gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                        gridTemplateColumns={{ xs: 'repeat(3, 1fr)', md: 'repeat(6, 1fr)' }}
                                        sx={{
                                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                            // "& > div": { gridColumn: { xs: 'span 3', sm: 'span 1.33', md: 'span 1' } },
                                        }}
                                    >
                                        {/* {"Use Google's location service?"} */}
                                        <Typography id="alert-dialog-title" fontSize='24px' textAlign='left' color='#000' fontWeight='bold'
                                            sx={{ gridColumn: "span 3", color: "#2664b1" }}
                                        >
                                            {"Registration"}
                                        </Typography>

                                    </Box>


                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                display: 'inline-block',
                                                width: 'fit-content',
                                                '&::before': {
                                                    content: '""',
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    backgroundColor: 'rgba(0, 0, 0, 0.3)', // Adjust the color and opacity as needed
                                                    borderRadius: '4px', // Match the border-radius of the Alert component
                                                    zIndex: 1,
                                                }
                                            }}
                                        >
                                            {isRegisterStatus && (
                                                <Alert
                                                    severity="success"
                                                    sx={{
                                                        position: 'relative',
                                                        zIndex: 2,
                                                        backgroundColor: 'secondary.main', // Secondary color
                                                        color: 'white', // Text color to ensure readability
                                                    }}
                                                >
                                                    สมัครสมาชิกสำเร็จ.
                                                </Alert>
                                            )}
                                        </Box>
                                    </Box>


                                </DialogTitle>

                                <DialogContent
                                    sx={{ padding: 10 }}>
                                    <Box
                                        display="grid"
                                        gap="30px"
                                        // gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                        gridTemplateColumns={{ xs: 'repeat(3, 1fr)', md: 'repeat(6, 1fr)' }}
                                        sx={{
                                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                            // "& > div": { gridColumn: { xs: 'span 3', sm: 'span 1.33', md: 'span 1' } },
                                        }}
                                    >
                                        {isRegister && (
                                            <>
                                                <TextField
                                                    label="First Name"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.firstname}
                                                    name="firstname"
                                                    error={
                                                        Boolean(touched.firstname) && Boolean(errors.firstname)
                                                    }
                                                    helperText={touched.firstname && errors.firstname}
                                                    sx={{
                                                        gridColumn: "span 3",
                                                        "& .MuiInputBase-input": {
                                                            color: "#2664b1", // text color
                                                        },
                                                        "& .MuiFormLabel-root": {
                                                            // color: "#2664b1", // label color
                                                        },
                                                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                                            // borderColor: "#2664b1", // border color
                                                        },
                                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                            // borderColor: "#2664b1", // border color when focused
                                                        },
                                                        "& .MuiInputBase-root": {
                                                            backgroundColor: "#f0f0f0", // background color of the input field
                                                        },

                                                    }}
                                                    size="small"
                                                    autoComplete="off"
                                                />
                                                <TextField
                                                    label="Last Name"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.lastname}
                                                    name="lastname"
                                                    error={Boolean(touched.lastname) && Boolean(errors.lastname)}
                                                    helperText={touched.lastname && errors.lastname}
                                                    sx={{
                                                        gridColumn: "span 3",
                                                        "& .MuiInputBase-input": {
                                                            color: "#2664b1", // text color
                                                        },
                                                        "& .MuiFormLabel-root": {
                                                            // color: "#2664b1", // label color
                                                        },
                                                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                                            // borderColor: "#2664b1", // border color
                                                        },
                                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                            // borderColor: "#2664b1", // border color when focused
                                                        },
                                                        "& .MuiInputBase-root": {
                                                            backgroundColor: "#f0f0f0", // background color of the input field
                                                        },
                                                    }}
                                                    size="small"
                                                    autoComplete="off"
                                                />
                                                <TextField
                                                    label="Email"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.email}
                                                    name="email"
                                                    error={Boolean(touched.email) && Boolean(errors.email)}
                                                    helperText={touched.email && errors.email}
                                                    sx={{
                                                        gridColumn: "span 3",
                                                        "& .MuiInputBase-input": {
                                                            color: "#2664b1", // text color
                                                        },
                                                        "& .MuiFormLabel-root": {
                                                            // color: "#2664b1", // label color
                                                        },
                                                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                                            // borderColor: "#2664b1", // border color
                                                        },
                                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                            // borderColor: "#2664b1", // border color when focused
                                                        },
                                                        "& .MuiInputBase-root": {
                                                            backgroundColor: "#f0f0f0", // background color of the input field
                                                        },
                                                    }}
                                                    size="small"
                                                    autoComplete="off"
                                                />
                                                <TextField
                                                    label="Tel"
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        const { value } = e.target;
                                                        // Filter out any non-numeric characters
                                                        const numericValue = value.replace(/[^0-9]/g, '');
                                                        handleChange({
                                                            target: {
                                                                name: e.target.name,
                                                                value: numericValue,
                                                            },
                                                        });
                                                    }}
                                                    value={values.password}
                                                    name="password"
                                                    error={Boolean(touched.password) && Boolean(errors.password)}
                                                    helperText={touched.password && errors.password}
                                                    sx={{
                                                        gridColumn: "span 3",
                                                        "& .MuiInputBase-input": {
                                                            color: "#2664b1", // text color
                                                        },
                                                        "& .MuiFormLabel-root": {
                                                            // color: "#2664b1", // label color
                                                        },
                                                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                                            // borderColor: "#2664b1", // border color
                                                        },
                                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                            // borderColor: "#2664b1", // border color when focused
                                                        },
                                                        "& .MuiInputBase-root": {
                                                            backgroundColor: "#f0f0f0", // background color of the input field
                                                        },
                                                    }}
                                                    size="small"
                                                    autoComplete="off"
                                                    inputProps={{
                                                        inputMode: 'numeric',
                                                        pattern: '[0-9]*'
                                                    }}
                                                />
                                                <FormControl component="fieldset" fullWidth sx={{ gridColumn: "span 6", mb: 2, }}>
                                                    <Grid container alignItems="center" spacing={2}>
                                                        <Grid item>
                                                            <FormLabel component="legend">Package: </FormLabel>
                                                        </Grid>
                                                        <Grid item>
                                                            <FormGroup
                                                                sx={{
                                                                    maxHeight: '150px', // Adjust this height as needed
                                                                    overflowY: 'auto',
                                                                    border: '1px solid #ccc', // Optional: Add a border for better visual distinction
                                                                    padding: '3px', // Optional: Add padding for better appearance
                                                                    borderRadius: 3,
                                                                    backgroundColor: "#f0f0f0", // background color of the input field

                                                                }}
                                                            >
                                                                <RadioGroup
                                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                                    name="plan"
                                                                    value={values.plan || "monthly"}
                                                                    // onChange={handleChange}
                                                                    onChange={(e) => {
                                                                        setFieldValue("plan", e.target.value);
                                                                        const selectedPackage = Array.isArray(values.packages) ? values.packages : [];
                                                                        setFieldValue("total_price", updateTotalPrice(selectedPackage, e.target.value));
                                                                    }}
                                                                    row
                                                                >
                                                                    <FormControlLabel value="monthly" control={<Radio />} label="รายเดือน" />
                                                                    <FormControlLabel value="annually" control={<Radio />} label="รายปี" />
                                                                </RadioGroup>
                                                            </FormGroup>

                                                        </Grid>
                                                    </Grid>
                                                    <FormGroup
                                                        sx={{
                                                            maxHeight: '150px', // Adjust this height as needed
                                                            overflowY: 'auto',
                                                            border: '1px solid #ccc', // Optional: Add a border for better visual distinction
                                                            padding: '10px', // Optional: Add padding for better appearance
                                                            borderRadius: 3,
                                                            backgroundColor: "#f0f0f0", // background color of the input field
                                                            marginTop: '3px'

                                                        }}
                                                    >
                                                        {packages.map((pack) => (
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={Array.isArray(values.packages) && values.packages.includes(pack.role_id)}
                                                                        onChange={(e) => {
                                                                            const { checked } = e.target;
                                                                            let selectedPackage;
                                                                            if (!Array.isArray(values.packages)) {
                                                                                selectedPackage = checked ? [pack.role_id] : [];
                                                                            } else {
                                                                                selectedPackage = checked
                                                                                    ? [...values.packages, pack.role_id]
                                                                                    : values.packages.filter((p) => p !== pack.role_id);
                                                                            }
                                                                            setFieldValue("packages", selectedPackage);
                                                                            setFieldValue("total_price", updateTotalPrice(selectedPackage, values.plan || "monthly"));
                                                                        }}
                                                                        name={pack.role_id}
                                                                        sx={{
                                                                            color: '#2664b1',
                                                                            '&.Mui-checked': {
                                                                                color: '#2664b1',
                                                                            },
                                                                        }}
                                                                    />
                                                                }

                                                                label={
                                                                    // <Box sx={{ color: '#2664b1' }}>{`${pack.name} - ${pack.packet_price.toLocaleString()}฿`}</Box>
                                                                    <Box sx={{ color: '#2664b1' }}>{`${pack.name} - ${pack.package_price[values.plan || "monthly"].toLocaleString()}฿`}</Box>
                                                                }
                                                                sx={{
                                                                    '& .MuiFormControlLabel-label': {
                                                                        color: '#2664b1',
                                                                    },
                                                                }}
                                                                key={pack.role_id}
                                                            />
                                                        ))}


                                                    </FormGroup>
                                                    {touched.packets && errors.packets && (
                                                        <Box sx={{ color: 'error.main', mt: 1 }}>{errors.packets}</Box>
                                                    )}
                                                </FormControl>

                                                <Box sx={{ gridColumn: "span 2", }}>
                                                    <TextField
                                                        label="Package Total Price"
                                                        type="text"
                                                        value={values.total_price ? values.total_price.toLocaleString() : ''}
                                                        name="total_price"
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        fullWidth
                                                        sx={{
                                                            width: '50%',
                                                            marginTop: -4,
                                                            "& .MuiInputBase-input": {
                                                                color: "#2664b1", // text color
                                                            },
                                                            "& .MuiFormLabel-root": {
                                                                // color: "#2664b1", // label color
                                                            },
                                                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                                                // borderColor: "#2664b1", // border color
                                                            },
                                                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                                // borderColor: "#2664b1", // border color when focused
                                                            },
                                                            "& .MuiInputBase-root": {
                                                                backgroundColor: "#f0f0f0", // background color of the input field
                                                            },
                                                        }}
                                                        size="small"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Box>

                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center', // Vertically center the items
                                                        gridColumn: "span 2", marginTop: -4, padding: 0
                                                    }}
                                                >

                                                    <Box
                                                        component="img"
                                                        src={KbankIcon}
                                                        alt="Logo"
                                                        sx={{
                                                            width: 32,
                                                            height: 32,
                                                        }}
                                                    />
                                                    <Box sx={{ marginLeft: 2 }}>
                                                        <p style={{ margin: 0, }}>โอนผ่านบัญชีธนาคาร</p>
                                                        <p style={{ margin: 0 }}>เลขที่บัญชี 047-2-27169-7</p>
                                                        <p style={{ margin: 0 }}>MR.CHALERMPOL NEAMSRI</p>
                                                    </Box>
                                                </Box>


                                                <Button
                                                    component="label"
                                                    role={undefined}
                                                    variant="contained"
                                                    tabIndex={-1}
                                                    sx={{
                                                        width: '70%',
                                                        gridColumn: "span 2",
                                                        marginTop: -4,
                                                        // backgroundColor: "#2664b1",
                                                        backgroundColor: fileName === 'Upload Slip' ? "#2664b1" : 'success.main',
                                                        fontSize: '16px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center', // Center the content vertically
                                                        textAlign: 'center', // Center the text horizontally
                                                        padding: '0px', // Add padding for better appearance
                                                        borderRadius: 3,

                                                    }}
                                                    size="small"

                                                >
                                                    {fileName}

                                                    <VisuallyHiddenInput
                                                        type="file"
                                                        id="image-upload-button"
                                                        // onChange={(event) => setFieldValue("slip_image", event.currentTarget.files[0])}
                                                        onChange={handleFileChange(setFieldValue)}
                                                        accept="image/*"
                                                        name="slip_image"
                                                    />
                                                    <CloudUploadIcon />
                                                </Button>

                                            </>

                                        )}
                                    </Box>

                                </DialogContent>

                                <DialogActions>
                                    <Button
                                        type="submit"
                                        sx={{
                                            m: "0rem 0",
                                            // p: "1rem",
                                            backgroundColor: '#2664b1',//palette.primary.main,
                                            color: palette.primary.contrastText,
                                            "&:hover": { color: palette.primary.main },
                                        }}
                                        onClick={handleSubmit}
                                    >
                                        {isLogin ? "LOGIN" : "REGISTER"}
                                    </Button>
                                    <Button onClick={() => {
                                        signUpClose()
                                        resetForm()
                                    }}>Close</Button>
                                </DialogActions>
                            </Dialog>

                        </Box>


                        <Typography
                            onClick={() => {
                                setPageType(isLogin ? "register" : "login");
                                resetForm();
                            }}
                            sx={{
                                textDecoration: "underline",
                                color: palette.primary.main,
                                "&:hover": {
                                    cursor: "pointer",
                                    color: palette.primary.light,
                                },
                            }}
                        >
                            {isLogin
                                ? ""
                                : ""}
                            {/* {isLogin
                                ? "ไม่มีบัญชีผู้ใช้? ลงทะเบียนที่นี่"
                                : "มีบัญชีผู้ใช้อยู่แล้ว? เข้าสู่ระบบที่นี่"} */}
                        </Typography>

                        {/* <div id="signInDiv"></div> */}


                    </Box>

                    <Box>
                        <div>
                            <Dialog
                                open={openDialogOtp}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleCloseDialogOtp}
                                aria-describedby="alert-dialog-slide-description"
                                maxWidth={"xs"}
                                fullWidth={true}
                                sx={{
                                    zIndex: theme.zIndex.modal
                                }}
                            >
                                <DialogTitle
                                    sx={{
                                        fontSize: "24px",
                                    }}
                                >
                                    {"กรุณากรอก OTP !"}
                                </DialogTitle>
                                <DialogContent
                                // sx={{ display: "flex", justifyContent: "center" }}
                                >
                                    <DialogContentText id="alert-dialog-slide-description"></DialogContentText>

                                    <Box display="flex" justifyContent="center" alignItems="center"
                                    >
                                        {otp.map((data, index) => (
                                            <TextField
                                                // disabled={otpVerify === false ? true : false}
                                                key={index}
                                                type="text"
                                                inputProps={{
                                                    maxLength: 1,
                                                    style: {
                                                        textAlign: 'center',
                                                        color: otpVerify === false ? 'red' : '#333',
                                                    }
                                                }}
                                                value={data}
                                                onChange={e => handleChangeOTP(e.target, index)}
                                                onKeyDown={e => handleKeyDown(e, index)}
                                                onKeyUp={e => handleKeyUp(e, index)}
                                                inputRef={el => (inputRefs.current[index] = el)}
                                                autoComplete="off"
                                                sx={{
                                                    marginRight: index < 6 - 1 ? 1 : 0, width: '40px',
                                                    background: '#cccccc',
                                                    borderRadius: '8px',
                                                    border: 0,
                                                    // border: otpVerify === false ? '1px solid red' : '1px solid #cccccc',
                                                    padding: '10px',
                                                    boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.1), -4px -4px 8px rgba(255, 255, 255, 0.5)',
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '8px',
                                                        background: 'inherit',
                                                        '& fieldset': {
                                                            border: 'none',
                                                        },
                                                        '&:hover fieldset': {
                                                            border: 'none',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            border: 'none',

                                                        },
                                                    },
                                                    '& .MuiInputBase-input': {
                                                        padding: 0,
                                                        textAlign: 'center',
                                                        fontSize: '1rem',
                                                        fontWeight: 600,
                                                        letterSpacing: '1px',
                                                        color: '#333',
                                                    },

                                                    // '& .MuiOutlinedInput-root': {
                                                    //     borderRadius: '8px',
                                                    //     background: 'inherit',
                                                    //     border: 'none',
                                                    //     '&:hover fieldset': {
                                                    //         border: 'none',
                                                    //     },
                                                    //     '&.Mui-focused fieldset': {
                                                    //         border: 'none',
                                                    //     },
                                                    // },

                                                }}

                                            />
                                        ))}
                                    </Box>
                                    <Box display="flex" justifyContent="center" alignItems="center">
                                        {otpVerify === false && (
                                            <Typography variant="body2" color="error">
                                                OTP verification failed. Please check your OTP.
                                            </Typography>
                                        )}
                                    </Box>
                                </DialogContent>

                            </Dialog>
                        </div>
                    </Box>



                    <Dialog
                        open={open}
                        onClose={forgetPasswordClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title" fontSize='24px'>
                            {"Forget your ID or password?"}
                        </DialogTitle>
                        <DialogContent>
                            <Box
                                width='70vw'
                                height='7vh'
                                sx={{
                                    width: {
                                        xs: '70vw',
                                        sm: '50vw',
                                        md: '30vw',
                                        lg: '30vw'
                                    },
                                    height: {
                                        xs: '7vh',
                                        sm: '5vh',
                                        md: '5vh',
                                        lg: '7vh'
                                    }
                                }}

                            >
                                <Typography color='black' fontSize='16px'>
                                    <p style={{
                                        margin: '0',
                                        padding: '0'
                                    }}>Your ID is: your  <span style={{ fontSize: '20px', color: '#3445DD', fontWeight: 'bold', paddingLeft: '5px' }}>Email address.</span></p>
                                </Typography>
                                <Typography color='black' fontSize='16px'>
                                    <p style={{
                                        margin: '0',
                                        padding: '0'
                                    }}>Your Password is: your  <span style={{ fontSize: '20px', color: '#3445DD', fontWeight: 'bold', paddingLeft: '5px' }}>Phone number.</span></p>
                                </Typography>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={forgetPasswordClose}>Close</Button>
                        </DialogActions>
                    </Dialog>

                    <div>
                        <Backdrop
                            sx={{
                                color: "#fff",
                                zIndex: (theme) => theme.zIndex.drawer + 9999,
                            }}
                            open={openBackdrop}
                            onClick={handleCloseBackdrop}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>

                    </div>
                </form>
            )
            }
        </Formik >
    )



}

export default Form;