import { useState, useEffect, forwardRef, createRef, useContext } from 'react';
import {
    Box, Button, Container, Typography, Link, Grid, useMediaQuery, TextField, FormControl, FormLabel, FormGroup, FormControlLabel,
    RadioGroup, Radio, Checkbox,
    useTheme,



} from '@mui/material';

import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../components/Header";
import { __fetching_data__ } from '../../components/api.js';
import { useDispatch, useSelector } from "react-redux";
import KbankIcon from '../../images/kbank_icon.png';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const registerSchema = yup.object().shape({
    firstname: yup.string().required("required"),
    lastname: yup.string().required("required"),
    email: yup.string().email("invalid email").required("required"),
    password: yup.string().required("required"),
    packets: yup.array().min(1, "Select at least one interest").notRequired(),
    plan: yup.string(),
});
const initialValuesRegister = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    location: "",
    occupation: "",
    picture: "",
    packages: [],
    total_price: 0,
    slip_image: null,
    plan: "monthly"
};

const base_api_url =
    process.env.NODE_ENV === "production"
        ? window.location.protocol === "https:"
            ? process.env.REACT_APP_API_HTTPS_URL
            : process.env.REACT_APP_API_HTTP_URL
        : "/application";

export default function UpgradePlanPage() {
    const { palette } = useTheme();
    const csrfToken = useSelector((state) => state.csrfToken);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const handleFormSubmit = async (values, onSubmitProps) => {
        console.log(values, onSubmitProps);
    };
    const [fileName, setFileName] = useState('Upload Slip');
    const [packages, setPackage] = useState([]);
    const getPackage = async () => {
        const roleResponse = await __fetching_data__({ url: `${base_api_url}/get_package`, method: "GET", headers: { "xsrf-token": csrfToken } });
        console.log(roleResponse)
        setPackage(roleResponse.data)
    }
    useEffect(() => {
        getPackage();
    }, []);

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const updateTotalPrice = (selectedPackage, plan) => {
        var total = packages
            .filter(pack => selectedPackage.includes(pack.role_id))
            .reduce((total, pack) => total + pack.package_price[plan], 0);
        return total
    };

    const handleFileChange = (setFieldValue) => (event) => {
        const file = event.currentTarget.files[0];
        console.log(file)
        if (file) {
            setFieldValue("slip_image", file);
            setFileName(file.name);

        }
    };

    return (
        <Formik
            onSubmit={handleFormSubmit}
            // initialValues={isLogin ? initialValuesLogin : initialValuesRegister}
            // validationSchema={isLogin ? loginSchema : registerSchema}
            initialValues={initialValuesRegister}
            validationSchema={registerSchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box m="20px">

                        <Grid container justifyContent="space-between" alignItems="center">
                            <Header title="Subscription" subtitle="" />
                            <Box>
                                {/* <Button type="submit" variant="contained"
                                    onClick={handleCreateOpen}
                                    sx={{
                                        backgroundColor: colors.blueAccent[400]
                                    }} >
                                    Create New Menu
                                </Button> */}
                            </Box>
                        </Grid>


                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                            p="30px"
                        >
                            <FormControl component="fieldset" fullWidth sx={{ gridColumn: "span 6", mb: 2, }}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                        <FormLabel component="legend">Package: </FormLabel>
                                    </Grid>
                                    <Grid item>
                                        <FormGroup
                                            sx={{
                                                maxHeight: '150px', // Adjust this height as needed
                                                overflowY: 'auto',
                                                border: '1px solid #ccc', // Optional: Add a border for better visual distinction
                                                padding: '3px', // Optional: Add padding for better appearance
                                                borderRadius: 3,
                                                backgroundColor: "#f0f0f0", // background color of the input field

                                            }}
                                        >
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="plan"
                                                value={values.plan || "monthly"}
                                                // onChange={handleChange}
                                                onChange={(e) => {
                                                    setFieldValue("plan", e.target.value);
                                                    const selectedPackage = Array.isArray(values.packages) ? values.packages : [];
                                                    setFieldValue("total_price", updateTotalPrice(selectedPackage, e.target.value));
                                                }}
                                                row
                                            >
                                                <FormControlLabel value="monthly" control={<Radio />} label="รายเดือน" />
                                                <FormControlLabel value="annually" control={<Radio />} label="รายปี" />
                                            </RadioGroup>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            label="Package Total Price"
                                            type="text"
                                            value={values.total_price ? values.total_price.toLocaleString() : ''}
                                            name="total_price"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth
                                            sx={{
                                                width: '80%',
                                                // marginTop: -4,
                                                "& .MuiInputBase-input": {
                                                    color: "#2664b1", // text color
                                                },
                                                "& .MuiFormLabel-root": {
                                                    // color: "#2664b1", // label color
                                                },
                                                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                                    // borderColor: "#2664b1", // border color
                                                },
                                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    // borderColor: "#2664b1", // border color when focused
                                                },
                                                "& .MuiInputBase-root": {
                                                    backgroundColor: "#f0f0f0", // background color of the input field
                                                },
                                            }}
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <FormGroup
                                    sx={{
                                        maxHeight: '150px', // Adjust this height as needed
                                        overflowY: 'auto',
                                        border: '1px solid #ccc', // Optional: Add a border for better visual distinction
                                        padding: '10px', // Optional: Add padding for better appearance
                                        borderRadius: 3,
                                        backgroundColor: "#f0f0f0", // background color of the input field
                                        marginTop: '3px'

                                    }}
                                >
                                    {packages.map((pack) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={Array.isArray(values.packages) && values.packages.includes(pack.role_id)}
                                                    onChange={(e) => {
                                                        const { checked } = e.target;
                                                        let selectedPackage;
                                                        if (!Array.isArray(values.packages)) {
                                                            selectedPackage = checked ? [pack.role_id] : [];
                                                        } else {
                                                            selectedPackage = checked
                                                                ? [...values.packages, pack.role_id]
                                                                : values.packages.filter((p) => p !== pack.role_id);
                                                        }
                                                        setFieldValue("packages", selectedPackage);
                                                        setFieldValue("total_price", updateTotalPrice(selectedPackage, values.plan || "monthly"));
                                                    }}
                                                    name={pack.role_id}
                                                    sx={{
                                                        color: '#2664b1',
                                                        '&.Mui-checked': {
                                                            color: '#2664b1',
                                                        },
                                                    }}
                                                />
                                            }

                                            label={
                                                <Box sx={{ color: '#2664b1' }}>{`${pack.name} - ${pack.package_price[values.plan || "monthly"].toLocaleString()}฿`}</Box>
                                            }
                                            sx={{
                                                '& .MuiFormControlLabel-label': {
                                                    color: '#2664b1',
                                                },
                                            }}
                                            key={pack.role_id}
                                        />
                                    ))}
                                </FormGroup>
                                {touched.packets && errors.packets && (
                                    <Box sx={{ color: 'error.main', mt: 1 }}>{errors.packets}</Box>
                                )}
                            </FormControl>



                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center', // Vertically center the items
                                    gridColumn: "span 2", marginTop: -4, padding: 0
                                }}
                            >

                                <Box
                                    component="img"
                                    src={KbankIcon}
                                    alt="Logo"
                                    sx={{
                                        width: 32,
                                        height: 32,
                                    }}
                                />
                                <Box sx={{ marginLeft: 2 }}>
                                    <p style={{ margin: 0, }}>โอนผ่านบัญชีธนาคาร</p>
                                    <p style={{ margin: 0 }}>เลขที่บัญชี 047-2-27169-7</p>
                                    <p style={{ margin: 0 }}>MR.CHALERMPOL NEAMSRI</p>
                                </Box>
                            </Box>


                            <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                sx={{
                                    width: '70%',
                                    gridColumn: "span 2",
                                    marginTop: -4,
                                    // backgroundColor: "#2664b1",
                                    backgroundColor: fileName === 'Upload Slip' ? "#2664b1" : 'success.main',
                                    fontSize: '16px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center', // Center the content vertically
                                    textAlign: 'center', // Center the text horizontally
                                    padding: '0px', // Add padding for better appearance
                                    borderRadius: 3,

                                }}
                                size="small"

                            >
                                {fileName}

                                <VisuallyHiddenInput
                                    type="file"
                                    id="image-upload-button"
                                    // onChange={(event) => setFieldValue("slip_image", event.currentTarget.files[0])}
                                    onChange={handleFileChange(setFieldValue)}
                                    accept="image/*"
                                    name="slip_image"
                                />
                                <CloudUploadIcon />
                            </Button>


                            <Button
                                type="submit"
                                sx={{
                                    m: "0rem 0",
                                    // p: "1rem",
                                    backgroundColor: '#2664b1',//palette.primary.main,
                                    color: palette.primary.contrastText,
                                    "&:hover": { color: palette.primary.main },
                                }}
                                onClick={handleSubmit}
                            >
                                {"SUBSCRIPTION"}
                            </Button>





                        </Box>

                    </Box>


                </form>

            )
            }
        </Formik >








    );
}