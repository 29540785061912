import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box, Grid, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/system';

const DialogComponent = ({ open, onClose, title, content, action, maxWidth = "lg", ...props }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth={maxWidth} {...props}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{content}</DialogContent>
            <DialogActions>{action}</DialogActions>
        </Dialog>
    )
}

// const DialogComponent = styled(DataGrid)({

// })

export default DialogComponent;