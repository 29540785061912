import React, { useState, useEffect, useRef } from "react";

import {
    Typography,
    TextField,
    Button,
    Container,
    useTheme,
    useMediaQuery,
    MenuItem,
    InputAdornment,
    OutlinedInput,
    Autocomplete,
    Select,
    FormControl,
    InputLabel,
    Grid,
    Paper,
    Box,
    FormGroup,
    List,
    ListItem,
    ListItemText,
    Popover,
    Menu,
    Tab,
    Tabs,
} from "@mui/material";
// import { colorTokens } from "../../../theme";
import { setCsrfToken, setLogin, setUserDetail } from "../../../state";
import { useSelector, useDispatch } from "react-redux";

const TabAccount = ({ userDetail, colors, isMobile, activeTab }) => {
    const dispatch = useDispatch();
    //   const theme = useTheme();
    //   const colors = colorTokens(theme.palette.mode);
    //   const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const [localUserDetail, setLocalUserDetail] = useState(userDetail);
    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setLocalUserDetail((prevData) => ({ ...prevData, [name]: value }));
    };

    const [isEditProfile, setIsEditProfile] = useState(false);
    const handleEditProfile = (e) => {
        const { name, value } = e.target;
        if (name == "edit_profile") {
            setIsEditProfile(true);
        } else {
            setIsEditProfile(!isEditProfile);
            // dispatch(setUserDetail({
            //     img_src: "",
            //     fullname: userData.data.firstname + " " + userData.data.lastname,
            //     firstname: userData.data.firstname,
            //     lastname: userData.data.lastname,
            //     email: userData.data.email,
            //     app_id: userData.data.app_id,
            //     user_type: userData.data.user_type
            //   }));
        }
    };
    const [isUpdateEmail, setIsUpdateEmail] = useState(false);

    useEffect(() => {
        // console.log(activeTab)
    }, [activeTab]);

    return (
        <Grid container>
            <Grid container spacing={2}>
                <Grid
                    container
                    spacing={1}
                    columns={{ md: 12 }}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{
                        marginTop: 1,
                    }}
                >
                    {!isEditProfile ? (
                        <Button
                            name="edit_profile"
                            variant="contained"
                            type="button"
                            size={isMobile ? "small" : "medium"}
                            sx={{
                                marginLeft: 1,
                                textTransform: "none",
                                backgroundColor: colors.blueAccent[400],
                                "&:hover": {
                                    backgroundColor: colors.blueAccent[400],
                                },
                            }}
                            onClick={(e) => handleEditProfile(e)}
                        >
                            Edit Profile
                        </Button>
                    ) : (
                        <Button
                            name="save"
                            variant="contained"
                            type="submit"
                            size={isMobile ? "small" : "medium"}
                            sx={{
                                marginLeft: 1,
                                textTransform: "none",
                                backgroundColor: colors.greenAccent[500],
                                "&:hover": {
                                    backgroundColor: colors.greenAccent[500],
                                },
                            }}
                            onClick={(e) => handleEditProfile(e)}
                        >
                            Save
                        </Button>
                    )}
                    {isEditProfile && (
                        <Button
                            name="cancel_edit_profile"
                            variant="contained"
                            type="submit"
                            size={isMobile ? "small" : "medium"}
                            sx={{
                                marginLeft: 1,
                                textTransform: "none",
                                backgroundColor: colors.redAccent[500],
                                "&:hover": {
                                    backgroundColor: colors.redAccent[500],
                                },
                            }}
                            onClick={(e) => handleEditProfile(e)}
                        >
                            Cancel
                        </Button>
                    )}
                </Grid>
            </Grid>
            {/** row ================================================================================================================================== */}
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="First Name"
                        name="firstname"
                        variant={!isEditProfile ? "standard" : "outlined"}
                        margin="normal"
                        value={localUserDetail?.firstname}
                        onChange={handleChangeInput}
                        size="small"
                        InputProps={{
                            readOnly: !isEditProfile,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Last Name"
                        name="lastname"
                        variant={!isEditProfile ? "standard" : "outlined"}
                        margin="normal"
                        value={localUserDetail?.lastname}
                        onChange={handleChangeInput}
                        size="small"
                        InputProps={{
                            readOnly: !isEditProfile,
                        }}
                    />
                </Grid>
            </Grid>
            {/** row ================================================================================================================================== */}
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        variant={!isUpdateEmail ? "standard" : "outlined"}
                        margin="normal"
                        value={localUserDetail?.email}
                        onChange={handleChangeInput}
                        size="small"
                        InputProps={{
                            readOnly: !isUpdateEmail,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}></Grid>
            </Grid>
            {/** row ================================================================================================================================== */}
        </Grid>
    );
};

export default TabAccount;
