import { useState, useEffect, useRef } from "react";
import {
    Typography,
    TextField,
    Button,
    Container,
    useTheme,
    useMediaQuery,
    MenuItem,
    InputAdornment,
    OutlinedInput,
    Autocomplete,
    Select,
    FormControl,
    InputLabel,
    Grid,
    Paper,
    Box,
    FormGroup,
    List,
    ListItem,
    ListItemText,
    Popover,
    Menu,
    Tab,
    Tabs,
    Card,
    CardContent,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PropTypes from "prop-types";

import IconButton from "@mui/material/IconButton";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { tokens } from "../../../theme";

import { styled } from "@mui/material/styles";
import CircularProgress, {
    circularProgressClasses,
} from "@mui/material/CircularProgress";
import LinearProgress, {
    linearProgressClasses,
} from "@mui/material/LinearProgress";

import { useSelector, useDispatch } from "react-redux";

// import { MuiTelInput } from "mui-tel-input";
const CustomTab = (props) => {
    return <Tab sx={{ height: "3px", ...props.sx }} {...props} />;
};

import TabAccount from "./tab_account";
import TabPassword from "./tab_password";
import TabAPI from "./tab_api";

import { setCsrfToken, setLogin, setUserDetail } from "../../../state";
import { useNavigate, useLocation } from 'react-router-dom';

import { __fetching_data__ } from '../../../components/api';

const ProfilePage = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { palette } = useTheme();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    // const [userDetail_dump, setUserDetail_dump] = useState({
    //   name: "John Doe",
    //   email: "john.doe@example.com",
    //   bio: "A software developer",

    //   firstname: "t_firstname",
    //   lastname: "t_lastname",
    //   phone_number: "",
    //   password: "",
    //   current_password: "",
    //   confirm_password: "",
    // });

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 100,
            },
        },
    };


    const [isTab, setTabValue] = useState(0);
    //==============================================================================================
    const dispatch = useDispatch();
    const userDetail = useSelector((state) => state.userDetail);
    const [localUserDetail, setLocalUserDetail] = useState(userDetail);


    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    const userId = useSelector((state) => state.user);

    const handleChangeTab = async (event, newValue) => {
        setTabValue(newValue);
        navigate(`#${newValue}`);

        // const get_user = await __fetch_user__(userId);
        // if (get_user.status) {
        //     dispatch(setUserDetail({
        //         ...userDetail,
        //         app_id: get_user.data.app_id,
        //         app_secret: null,
        //     }));
        // }

    };

    const CustomTabPanel = (props) => {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 1, width: "%100", ...props.sx }}>{children}</Box>
                )}
            </div>
        );
    }
    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    // const Item = styled(Paper)(({ theme }) => ({
    //     backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    //     ...theme.typography.body2,
    //     padding: theme.spacing(1),
    //     textAlign: "center",
    //     color: theme.palette.text.secondary,
    // }));

    useEffect(() => {
        const hash = location.hash.substr(1);
        if (hash) {
            setTabValue(parseInt(hash));
            navigate(`#${hash}`);
        } else {
            setTabValue(0);
            navigate(`#0`);
        }

    }, []);

    const click1 = (e) => {
        // console.log(mainState)
    };



    const handleTabAPIDataChange = (updatedData) => {
    };

    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Container>
            <Card
                sx={{
                    // minWidth: 275,
                    height: "100vh",
                }}
            >
                <CardContent sx={{ paddingY: 0 }}>
                    <Grid
                        spacing={1}
                        container
                        sx={
                            {
                                // backgroundColor: "blue",
                            }
                        }
                    >
                        <Grid item xs={6} md={12} sx={{}}>
                            <Box
                                sx={{
                                    borderBottom: 1,
                                    borderColor: "divider",
                                }}
                            >
                                <Tabs
                                    value={isTab}
                                    onChange={handleChangeTab}
                                    aria-label="profile_setting_tabs"
                                    variant={isMobile ? "scrollable" : "standard"}
                                    scrollButtons
                                    allowScrollButtonsMobile
                                    sx={{
                                        height: 40,
                                        minHeight: 40,
                                        borderBottom: "1px solid #e8e8e8",
                                        "& .MuiTabs-indicator": {
                                            height: 2,
                                        },
                                        "& .MuiTab-root": {
                                        },
                                    }}
                                >
                                    <CustomTab
                                        label="Account"
                                        {...a11yProps(0)}
                                        sx={{ textTransform: "none", padding: 0, height: "10px" }}
                                    />
                                    <CustomTab
                                        label="Password"
                                        {...a11yProps(1)}
                                        sx={{ textTransform: "none", padding: 0, height: "10px" }}
                                    />
                                    <CustomTab
                                        label="API"
                                        {...a11yProps(2)}
                                        sx={{ textTransform: "none", padding: 0, height: "10px" }}
                                    />
                                </Tabs>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box sx={{ marginTop: 1 }}>
                        <CustomTabPanel value={isTab} index={0}>
                            <TabAccount
                                userDetail={localUserDetail}
                                colors={colors}
                                isMobile={isMobile}
                                activeTab={isTab}
                                setLocalUserDetail={setLocalUserDetail}
                            />
                        </CustomTabPanel>

                        <CustomTabPanel value={isTab} index={1}>
                            <TabPassword
                                userDetail={localUserDetail}
                                colors={colors}
                                isMobile={isMobile}
                            />
                        </CustomTabPanel>
                        <CustomTabPanel value={isTab} index={2}>
                            <TabAPI
                                userDetail={localUserDetail}
                                colors={colors}
                                isMobile={isMobile}
                                onTabDataChange={handleTabAPIDataChange}
                            />
                        </CustomTabPanel>
                    </Box>
                </CardContent>
            </Card>
        </Container>
    );
};

export default ProfilePage;
