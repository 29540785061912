import { useSelector, useDispatch } from "react-redux";
import { setCsrfToken, setLogin, setUserDetail, setIpAddress } from "../state";

const __fetching_data__ = async ({ url = null, method = "GET", headers = {}, body = null, isUploadImg = false }) => {
    try {
        // const ip = await __fetchIp__();
        const ip = {
            ip: 'test'
        }

        headers = {
            // ...{
            //     "Accept": "application/json",
            //     "Content-Type": "application/json",
            //     // "Content-Type": "text/plain",
            //     "x-forwarded-for": ip.ip
            // },
            ...(isUploadImg
                ? { "x-forwarded-for": ip?.ip || ip?.ipString || ip?.ipAddress }
                : {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "x-forwarded-for": ip?.ip || ip?.ipString || ip?.ipAddress
                }),
            ...headers,
        };
        const response = await fetch(`${url}`, {
            method: method,
            credentials: 'include',
            headers: headers,
            body: isUploadImg ? body : isJSONString(body) ? body : JSON.stringify(body)
        });
        const content = await response.json();
        if (response.status === 500) {
            return content;
        }
        if (response.status === 401) {
            return content;
        }
        // if (response.status === 401) {
        //     window.location.href = '/logout'
        //     return;
        // }
        if (response.status === 302) {
            // const redirectLocation = response.headers.get('Location');
            // if (redirectLocation) {
            //     window.location.href = 'logout'//redirectLocation;
            // } else {
            //     throw new Error('Redirect location not found');
            // }
            window.location.href = '/logout'
            return;
        }
        if (!response.ok) {
            throw new Error(`Failed to fetch : ${response.status}`);
        }
        return content;
    } catch (error) {
        // console.error("An error occurred:", url, ": ", error);
        throw error;

    }
}

const useFetchingData = () => {
    const ipAddress = useSelector((state) => state.ipAddress);
    const __fetching_data__ = async ({ url = null, method = "GET", headers = {}, body = null, isUploadImg = false }) => {
        try {
            headers = {
                ...(isUploadImg
                    ? { "x-forwarded-for": ipAddress }
                    : {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "x-forwarded-for": ipAddress
                    }),
                ...headers,
            };

            const response = await fetch(`${url}`, {
                method: method,
                credentials: 'include',
                headers: headers,
                body: isUploadImg ? body : isJSONString(body) ? body : JSON.stringify(body)
            });
            const content = await response.json();
            // console.log(response.status, content, response.headers.get('Location'))
            if (response.status === 400) {
                return content;
            }
            if (response.status === 401 && content.pathname) {
                window.location.href = content.pathname;
                return content;
            }
            if (response.status === 500) {
                return content;
            }
            if (response.status === 302) {
                window.location.href = content.pathname;
                // window.location.href = '/logout'
                return;
            }
            if (!response.ok) {
                throw new Error(`Failed to fetch : ${response.status}`);
            }

            return content;
        } catch (error) {
            throw error;
        }
    };

    return { __fetching_data__ };
};

const useFetchingUser = () => {
    const ipAddress = useSelector((state) => state.ipAddress);
    const userId = useSelector((state) => state.user);
    const userToken = useSelector((state) => state.token);
    const userData = async () => {
        try {
            const headers = {
                "Authorization": `Bearer ${userToken}`,
                "Accept": "application/json",
                "Content-Type": "application/json",
                "x-forwarded-for": ipAddress
            }
            const response = await fetch(`${window.config.base_api_url}/userprofile1/${userId}`, {
                method: method,
                credentials: 'include',
                headers: headers,
            });
            const content = await response.json();
            return content;
        } catch (error) {
            throw error;
        }
    }

    if (userData.status) {
        dispatch(setUserDetail({
            Id: userId,
            img_src: userData.data.img_src ? userData.data.img_src : "",
            fullname: (userData.data.firstname ? userData.data.firstname : "") + " " + (userData.data.lastname ? userData.data.lastname : ""),
            firstname: userData.data.firstname ? userData.data.firstname : "",
            lastname: userData.data.lastname ? userData.data.lastname : "",
            email: userData.data.email ? userData.data.email : "",
            app_id: userData.data.app_id ? userData.data.app_id : "",
            user_type: userData.data.user_type ? userData.data.user_type : "",
            permissions: userData.data.permissions ? userData.data.permissions : ""
        }));
    }

    return { userData }
}

const __fetchIp__ = async () => {
    try {
        const url = 'https://api64.ipify.org?format=json';
        // const url = 'https://api.bigdatacloud.net/data/client-ip';
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Failed to fetch IP');
        }
        return await response.json();
    } catch (error) {
        console.log(error)
    }
};

function isJSONString(str) {
    try {
        JSON.stringify(JSON.parse(str));
        return true;
    } catch (e) {
        return false;
    }
}

export { __fetching_data__, __fetchIp__, useFetchingData };