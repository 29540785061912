import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mode: "light",
    user: null,
    token: null,
    csrfToken: null,
    posts: [],
    userDetail: {},
    auth: {},
    leftMenu: "",
    chatbot: null,
    sessionId: null,
    ipAddress: null

};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setSession: (state) => {
            state.sessionId = action.payload.sessionId;
        },
        setIpAddress: (state, action) => {
            state.ipAddress = action.payload.ipAddress;
        },
        setMode: (state) => {
            state.mode = state.mode === "light" ? "dark" : "light";
        },
        setCsrfToken: (state, action) => {
            state.csrfToken = action.payload.csrfToken;
        },
        setLogin: (state, action) => {
            state.user = action.payload.user;
            state.token = action.payload.token;
            // state.auth.userType = action.payload.userType;
        },
        setLogout: (state) => {
            state.user = null;
            state.token = null;
            // state.auth.userType = null;
        },
        setUserDetail: (state, action) => {
            state.userDetail.Id = action.payload.Id;
            state.userDetail.img_src = action.payload.img_src;
            state.userDetail.fullname = action.payload.fullname;
            state.userDetail.firstname = action.payload.firstname;
            state.userDetail.lastname = action.payload.lastname;
            state.userDetail.email = action.payload.email;
            state.userDetail.app_id = action.payload.app_id;
            state.userDetail.app_secret = action.payload.app_secret;
            state.userDetail.user_type = action.payload.user_type;
            state.userDetail.permissions = action.payload.permissions;
        },
        setFriends: (state, action) => {
            if (state.user) {
                state.user.friends = action.payload.friends;
            } else {
                console.error("user friends non-existent :(");
            }
        },
        setPosts: (state, action) => {
            state.posts = action.payload.posts;
        },
        setPost: (state, action) => {
            const updatedPosts = state.posts.map((post) => {
                if (post._id === action.payload.post._id) return action.payload.post;
                return post;
            });
            state.posts = updatedPosts;
        },
        setLeftMenu: (state, action) => {
            state.leftMenu = action.payload

        },
        setTempPage: (state, action) => {
            state.chatbot = action.payload.chatbot
        }
    },
});

export const { setMode, setCsrfToken, setLogin, setLogout, setFriends, setPosts, setPost, setUserDetail, setLeftMenu, setTempPage, setIpAddress } =
    authSlice.actions;
export default authSlice.reducer;
