import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/system';

const DataGridComponent = styled(DataGrid)({
    '& .MuiDataGrid-root': {
        border: 'none',
    },
    '& .MuiDataGrid-cell': {
        borderRight: '1px solid #e0e0e0', // Vertical border for cells
    },
    '& .MuiDataGrid-virtualScroller': {
        '& .MuiDataGrid-row': {
            borderBottom: '1px solid #e0e0e0', // Horizontal border for rows
            cursor: 'pointer', // Change cursor to pointer
            '&:hover': {
                backgroundColor: '#f5f5f5', // Change background color on hover
            },
        },
        '&::-webkit-scrollbar': {
            width: '6px',
            height: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '10px',
            background: 'linear-gradient(darkblue, skyblue)',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
            borderRadius: '10px',
        },
    },
    '& .MuiDataGrid-cell:focus': {
        outline: 'none', // Remove the outline
    },
    '& .MuiDataGrid-cell:focus-within': {
        outline: 'none', // Remove the outline when cell is focused
    },
    '& .MuiDataGrid-cell--editing': {
        outline: 'none', // Remove the outline when cell is in editing mode
    },
    '& .MuiDataGrid-cell:focus-visible': {
        outline: 'none', // Remove the outline when cell is visible focused
    },
});


export default DataGridComponent;