import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { tokens } from "../../theme";
import { useTheme, Box, Container, Grid, Card, TextField, Button, Table, TableHead, TableRow, TableCell, TableBody, Typography, Paper } from '@mui/material';
import Header from "../../components/Header";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import CircularProgress from '@mui/material/CircularProgress';

import CardContent from '@mui/material/CardContent';

import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import StatBox from "../../components/StatBox";
import EmailIcon from "@mui/icons-material/Email";

import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';

import 'dayjs/locale/en-gb';
import 'dayjs/locale/es-us';
import { __fetching_data__ } from '../../components/api.js';

import dayjs from 'dayjs';

import { Formik } from 'formik'
import * as yup from "yup";


import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

import TableContainer from '@mui/material/TableContainer';
import Slider from '@mui/material/Slider';

import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';


const base_api_url =
    process.env.NODE_ENV === "production"
        ? window.location.protocol === "https:"
            ? process.env.REACT_APP_API_HTTPS_URL
            : process.env.REACT_APP_API_HTTP_URL
        : "/application";

const DejavuTickmatch = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const csrfToken = useSelector((state) => state.csrfToken);

    function createData(time, last, vol, type) {
        return { time, last, vol, type };
    }
    const [rows, setRows] = useState(
        [
            // createData('', '', '', ''),
            // createData('', '', '', ''),
        ]
    );

    const [rows2, setRows2] = useState(
        [
            // createData('', '', '', ''),
            // createData('', '', '', ''),
        ]
    );
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            fontSize: 14,
            backgroundColor: colors.blueAccent[500],//"#B6DBFB",//theme.palette.common.black,
            color: theme.palette.common.white,//"#1B7FD6",//theme.palette.common.white,
            fontWeight: 'bold',
            paddingTop: 8, // Set top padding to 0
            paddingBottom: 8, // Set bottom padding to 0
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            backgroundColor: colors.blueAccent[500],
            color: theme.palette.common.white,
            fontWeight: 'bold',
        },
    }));

    const now = dayjs();
    const today = now.format('YYYY-MM-DD');
    const today_value = dayjs(today);
    const [initialValues, setInitialValues] = useState({
        symbol1: "",
        symbol2: "",
        date1: today_value.subtract(1, 'day'),
        date2: today_value.subtract(1, 'day'),
    });
    const disableWeekends = (date) => {
        const day = date.day();
        return day === 0 || day === 6;
    };

    const handleChange = (e) => {
        const { name, value } = e.target
        console.log(name, value)
        setInitialValues((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }
    const handleDateChange = (name, value) => {
        setInitialValues((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    useEffect(() => {



    }, []);

    const formatDate = (date) => {
        if (typeof date === 'object') {
            return `${dayjs(date).year()}-${String(dayjs(date).month() + 1).padStart(2, '0')}-${String(dayjs(date).date()).padStart(2, '0')}`;
        }
        return date;
    };

    const objectToQueryString = (obj) => {
        return Object.keys(obj)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
            .join('&');
    }

    const [isFetch, setIsFetch] = useState({
        'form1': false,
        'form2': false,
    });

    const endProcess = () => {
        setIsFetch({
            'form1': false,
            'form2': false,
        });
    }

    const handleSubmitForm1 = async () => {
        setRows([]);
        setIsFetch(prevState => ({
            ...prevState,
            'form1': true,
        }));
        const values = {
            symbol: initialValues.symbol1,
            start_date: formatDate(initialValues.date1),
            end_date: formatDate(initialValues.date1)
        };
        const response = await __fetching_data__({ url: `${base_api_url}/tickmatch_dejavu_report1?${objectToQueryString(values)}`, method: "GET", headers: { "xsrf-token": csrfToken } });
        console.log(response)
        if (response.status) {
            let newData = [];
            response.data.map((value, index) => {
                newData.push(createData(value.Time, value.Last, value.Vol.toLocaleString(), value.Type))
            });
            setRows(newData);
        }
        endProcess();
    }
    const handleSubmitForm2 = async () => {
        setRows2([]);
        setIsFetch(prevState => ({
            ...prevState,
            'form2': true,
        }));
        const values = {
            symbol: initialValues.symbol2,
            start_date: formatDate(initialValues.date2),
            end_date: formatDate(initialValues.date2)
        };
        const response = await __fetching_data__({ url: `${base_api_url}/tickmatch_dejavu_report1?${objectToQueryString(values)}`, method: "GET", headers: { "xsrf-token": csrfToken } });
        if (response.status) {
            let newData = [];
            response.data.map((value, index) => {
                newData.push(createData(value.Time, value.Last, value.Vol.toLocaleString(), value.Type))
            });
            setRows2(newData);
        }
        endProcess();
    }

    const getValidDate = (date) => {
        let validDate = dayjs(date);
        while (disableWeekends(validDate)) {
            validDate = validDate.subtract(1, 'day');
        }
        return validDate;
    };


    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center" p="0">
                <Header title="TickMatch" subtitle="" />
            </Box>

            <Box p="0" display="Box"
                sx={{
                    height: {
                        xs: '50vh', // height for extra-small screens
                        sm: '60vh', // height for small screens
                        md: '82vh', // height for medium screens
                        lg: '90vh', // height for large screens
                        xl: '95vh', // height for extra-large screens
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '-2.3rem'
                }}>
                <Container maxWidth="xl" sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Paper
                        sx={{
                            flex: 1,
                            overflowY: 'auto',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            p: 2,
                            maxHeight: {
                                xs: '50vh', // height for extra-small screens
                                sm: '60vh', // height for small screens
                                md: '90vh', // height for medium screens
                                lg: '90vh', // height for large screens
                                xl: '95vh', // height for extra-large screens
                            },
                        }}
                    >

                        <Grid container spacing={5}
                            //display="flex" // alignItems="center" justifyContent="center"
                            xs={12} md={12}
                            sx={{
                                marginBottom: "1rem"
                            }}>
                            <Grid item xs={12} md={6} >
                                <Grid container spacing={2}>
                                    <Grid item xs={4} md={4}>
                                        <TextField
                                            fullWidth
                                            id="symbol1"
                                            name="symbol1"
                                            label="Symbol"
                                            variant="outlined"
                                            required
                                            size='small'
                                            value={initialValues.symbol1}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                            <DatePicker
                                                id="date1"
                                                name="date1"
                                                label="Date"
                                                slotProps={{ textField: { size: 'small' } }}
                                                // defaultValue={initialValues.date1}
                                                defaultValue={getValidDate(initialValues.date1)}
                                                maxDate={today_value.subtract(1, 'day')}
                                                onChange={(value) => handleDateChange('date1', value)}
                                                shouldDisableDate={disableWeekends}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <Button variant="contained" fullWidth
                                            sx={{
                                                backgroundColor: colors.blueAccent[500]
                                            }}
                                            type="button"
                                            onClick={handleSubmitForm1}
                                        >
                                            Search
                                        </Button>
                                    </Grid>
                                </Grid>

                                <Grid item md={12} display="flex" alignItems="center" justifyContent="center" marginTop={1} height={'100%'}>
                                    <TableContainer component={Paper}
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            '@media (min-width: 600px)': {
                                                width: '100%',
                                                height: '100%',
                                            },
                                            maxHeight: {
                                                xs: '50vh',
                                                sm: '60vh',
                                                md: '82vh',
                                                lg: '90vh',
                                                xl: '95vh',
                                            },
                                            overflowY: 'auto',
                                        }}
                                    >
                                        <Table aria-label="simple table" stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="center" sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>Time</StyledTableCell>
                                                    <StyledTableCell align="center" sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>Last</StyledTableCell>
                                                    <StyledTableCell align="center" sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>Vol</StyledTableCell>
                                                    <StyledTableCell align="center" sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>Type</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {isFetch.form1 ? (
                                                    <TableRow>
                                                        <TableCell colSpan={4} align="center">
                                                            <CircularProgress />
                                                        </TableCell>
                                                    </TableRow>
                                                ) : (
                                                    rows.map((row, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell align="center" style={{ padding: '4px' }}>{row.time}</TableCell>
                                                            <TableCell align="center" style={{ padding: '4px' }}>{row.last}</TableCell>
                                                            <TableCell align="center" style={{ padding: '4px', textAlign: 'right' }}>{row.vol}</TableCell>
                                                            <TableCell align="center" style={{ padding: '4px', color: row.type === 'BUY' ? 'green' : 'red' }}>{row.type}</TableCell>
                                                        </TableRow>
                                                    ))
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>

                            </Grid>
                            <Grid item xs={12} md={6}>

                                <Grid container spacing={2}>
                                    <Grid item xs={4} md={4}>
                                        <TextField
                                            fullWidth
                                            id="symbol2"
                                            name="symbol2"
                                            label="Symbol"
                                            variant="outlined"
                                            required
                                            size='small'
                                            value={initialValues.symbol2}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                            <DatePicker
                                                id="date2"
                                                name="date2"
                                                label="Date"
                                                slotProps={{ textField: { size: 'small' } }}
                                                defaultValue={getValidDate(initialValues.date2)}
                                                maxDate={today_value.subtract(1, 'day')}
                                                onChange={(value) => handleDateChange('date2', value)}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <Button variant="contained" fullWidth
                                            sx={{
                                                backgroundColor: colors.blueAccent[500]
                                            }}
                                            type="button"
                                            onClick={handleSubmitForm2}
                                        >
                                            Search
                                        </Button>
                                    </Grid>
                                </Grid>

                                <Grid item md={12} display="flex" alignItems="center" justifyContent="center" marginTop={1} height={'100%'}>
                                    <TableContainer component={Paper}
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            '@media (min-width: 600px)': {
                                                width: '100%',
                                                height: '100%',
                                            },
                                            maxHeight: {
                                                xs: '50vh',
                                                sm: '60vh',
                                                md: '82vh',
                                                lg: '90vh',
                                                xl: '95vh',
                                            },
                                            overflowY: 'auto',
                                        }}
                                    >
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="center" sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>Time</StyledTableCell>
                                                    <StyledTableCell align="center" sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>Last</StyledTableCell>
                                                    <StyledTableCell align="center" sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>Vol</StyledTableCell>
                                                    <StyledTableCell align="center" sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>Type</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {isFetch.form2 ? (
                                                    <TableRow>
                                                        <TableCell colSpan={4} align="center">
                                                            <CircularProgress />
                                                        </TableCell>
                                                    </TableRow>
                                                ) : (
                                                    rows2.map((row, index) => {
                                                        return (
                                                            <TableRow key={index}>
                                                                <TableCell align="center" style={{ padding: '4px' }}>{row.time}</TableCell>
                                                                <TableCell align="center" style={{ padding: '4px' }}>{row.last}</TableCell>
                                                                <TableCell align="center" style={{ padding: '4px' }}>{row.vol}</TableCell>
                                                                <TableCell align="center" style={{ padding: '4px', color: row.type === 'BUY' ? 'green' : 'red' }}>{row.type}</TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            </Box>
        </Box>

    );
};

export default DejavuTickmatch;
