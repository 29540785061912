import { Box, Typography, useTheme, useMediaQuery, createTheme } from "@mui/material";
import Form from "./form";

const LoginPage = () => {
    const theme = useTheme();
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
    return (
        <html>
            <head>

            </head>
            <Box>
                <Box
                    //background
                    position='absolute'
                    top='0'
                    left='0'
                    width='100vw'
                    height='100vh'
                    bgcolor='#3083FF'

                    zIndex='0'
                    sx={{
                        //backgroundImage:'url("https://i.postimg.cc/mgmY75RD/bg.png")'
                        backgroundImage: 'linear-gradient(180deg, #2765B2, #17245E);'
                    }}
                >
                </Box>
                <Box
                    display='flex'
                    position='absolute'
                    borderRadius='74px'
                    zIndex='20'
                    width='40vw'
                    height='70vh'
                    top='15vh'
                    right='8vw'
                    backgroundColor='#4D88DD'
                    alignItems='center'
                    justifyContent='center'

                    sx={{
                        backgroundImage: 'linear-gradient(180deg, #599FF1, #162185);',
                        display: {
                            xs: 'none',
                            sm: 'flex'
                        },
                        height: {
                            sm: '45vh',
                            md: '60vh',
                            lg: '70vh'
                        },
                        top: {
                            sm: '25vh',
                            md: '20vh',
                            lg: '15vh'
                        },
                        [theme.breakpoints.between('ex', 'sm')]: {

                        },//Mobile


                        [theme.breakpoints.between('md', '1030')]: {
                            height: '44vh',
                            top: '26vh'
                        },//IPadPro

                        /*
                        [theme.breakpoints.between('md', '1026')]:{
                          height: '60vh',
                          top: '20vh'
                        },//IPadMinihorizontal*/

                        [theme.breakpoints.between('sm', '770')]: {
                            height: '48vh',
                            top: '26vh'
                        },//IPadMini


                        //Blue radious box
                    }}
                >

                    <Box sx={{
                        //backgroundImage: 'url("https://i.postimg.cc/TP1gJwyz/logo-white-02.png")',
                        //backgroundSize: 'cover',
                        width: '10vw',
                        height: '10vh',
                        overflow: 'visible',
                        position: 'absolute',
                        top: '4vh',

                        [theme.breakpoints.between('sm', '1030')]: {
                            top: '4.4vh'
                        },//IPadPro

                        [theme.breakpoints.between('sm', '770')]: {
                            top: '5vh'
                        },//IPadMini
                    }}>

                        <Box
                            //Logo ideatrade
                            sx={{

                                [theme.breakpoints.between('lg', '1370')]: {
                                    width: '170%',
                                    position: 'absolute',
                                    left: '-2.5vw',
                                    marginBottom: '0'
                                },//IPadProHorizontal

                                [theme.breakpoints.between('sm', '1030')]: {
                                    width: '150%',
                                    position: 'absolute',
                                    left: '-1.5vw',
                                    marginBottom: '0'
                                },//IPadPro
                                [theme.breakpoints.between('sm', '830')]: {
                                    width: '130%',
                                    left: '-1vw',
                                    marginBottom: '0'
                                },//IPadAir
                                [theme.breakpoints.between('sm', '770')]: {
                                    width: '120%'
                                },//IPadMini
                            }}>

                            <img src="https://i.postimg.cc/TP1gJwyz/logo-white-02.png" alt="" width='100%' height='auto' />
                        </Box>

                        <Typography fontSize='28px' fontStyle='initial' sx={{
                            //fontFamily: 'Sora',
                            color: '#fff',
                            width: '27vh',
                            textAlign: 'center',
                            position: 'absolute',
                            top: {
                                sm: '7vh',
                                md: '10vh',
                                lg: '10vh',
                                xl: '10vh'
                            },

                            left: {
                                sm: '-12.7vw',
                                md: '-13.5vw',
                                lg: '-3vw',
                                xl: '-2.5vw'
                            },
                            fontSize: {
                                sm: '24px',
                                md: '24px',
                                lg: '22px',
                                xl: '22px'
                            },
                            [theme.breakpoints.between('lg', '1370')]: {
                                top: '12vh',
                                left: '-4.5vw',
                                fontSize: '30px'
                            },//IPadProHorizontal

                            [theme.breakpoints.between('md', '1190')]: {
                                top: '7.5vh',
                                left: '-4.5vw'
                            },//IPadAirHorizontal

                            [theme.breakpoints.between('md', '1100')]: {
                                top: '6vh',
                                left: '-12.5vw'
                            },//IPadPro

                            [theme.breakpoints.between('sm', '830')]: {
                                top: '6vh',
                                left: '-13.5vw'
                            },//IPadAir
                        }}>
                            <p style={{
                                width: '100%',
                                height: 'auto'
                            }}>Special Features for <br></br> our customers</p>
                        </Typography>

                        <Box
                            sx={{
                                width: '30vw',
                                height: '30vh',
                                //bgcolor: 'black',
                                position: 'absolute',
                                top: {
                                    sm: '20vh',
                                    md: '22vh',
                                    lg: '24vh',
                                    xl: '24vh'
                                },
                                left: {
                                    sm: '-10.4vw',
                                    md: '-7.8vh',
                                    lg: '-8.5vw',

                                    xl: '-10vh'
                                },
                                borderRadius: '25px 60px 25px 60px',
                                //backgroundImage: 'url("https://i.postimg.cc/vTmRvTx6/graph.png")',
                                //backgroundSize: 'cover'

                                [theme.breakpoints.between('lg', '1370')]: {
                                    left: '-10.5vw',
                                    top: '27.5vh',
                                },//IPadProHorizontal

                                [theme.breakpoints.between('md', '1190')]: {
                                    left: '-8vw',
                                    top: '22.5vh',
                                },//IPadAirHorizontal

                                [theme.breakpoints.between('md', '1100')]: {
                                    left: '-8.5vw',
                                    top: '16vh'
                                },//IPadPro
                                [theme.breakpoints.between('sm', '830')]: {
                                    left: '-10.5vw',
                                    top: '17vh'
                                },//IPadAir
                                [theme.breakpoints.between('sm', '770')]: {
                                    left: '-10.4vw',
                                    top: '19vh'
                                },//IPadMini
                            }}>

                            <Box sx={{
                                width: {
                                    sm: '35vw',
                                    md: '35vw',
                                    lg: '30vw',
                                    xl: '30vw'
                                },
                                height: '30vh',

                                [theme.breakpoints.between('lg', '1370')]: {
                                    width: '35vw',
                                    height: '35vh',
                                },//IPadProHorizontal

                                [theme.breakpoints.between('md', '1190')]: {
                                    width: '30vw',
                                    height: '30vh',
                                },//IPadAirHorizontal

                            }}>
                                <img src="https://i.postimg.cc/vTmRvTx6/graph.png" alt="" width='90%' height='auto' />
                            </Box>

                        </Box>

                    </Box>

                </Box>
                <Box
                    //radius_block
                    position="absolute"
                    width="90vw"
                    left='5vw'
                    height="82vh"
                    top="9vh"
                    borderRadius='74px'
                    bgcolor='white'
                    zIndex='10'
                >

                    <Box
                        //Login field position box
                        position='absolute'
                        width='100vw'
                        height='60vh'
                        top='18vh'
                        left='-25vw'
                        sx={{
                            top: {
                                xs: '19vh',
                                sm: '22vh',
                                md: '20vh',
                                lg: '20vh',
                            },
                            [theme.breakpoints.between('md', '1120')]: {
                                top: '26vh'
                            },//IPadAirHorizontal
                            [theme.breakpoints.between('sm', '1030')]: {
                                top: '26vh'
                            },//IPadPro
                            [theme.breakpoints.between('sm', '830')]: {
                                position: 'absolute',
                                top: '23vh'
                            },//IPadAir
                            [theme.breakpoints.between('sm', '770')]: {
                                position: 'absolute',
                                top: '22.5vh'
                            },//IPadMini
                        }}
                    >

                        <Box
                            width="100%"
                            backgroundColor={theme.palette.background.alt}
                            textAlign="center"
                            position='absolute'
                            sx={{
                                left: {
                                    xs: '22vw',
                                    sm: '0vw',
                                    md: '0vw',
                                    lg: '0vw'
                                }
                            }}
                        >
                            <Typography fontWeight="bold" fontSize="32px" color="#3083FF">
                                Sign In
                            </Typography>
                        </Box>

                        <Box
                            //width={isNonMobileScreens ? "35%" : "40%"}
                            sx={{
                                width: {
                                    xs: '80vw',
                                    sm: '35vw',
                                    md: '35vw',
                                    lg: '35vw'
                                },
                                position: 'absolute',
                                left: {
                                    xs: '30vw',
                                    sm: '32vw',
                                }
                            }}
                            // width="40%"
                            p="2rem 1.5rem 0 1.5rem"
                            m="2rem auto"
                            borderRadius="1.5rem"
                            backgroundColor={theme.palette.background.alt}
                        >
                            <Form />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </html>
    );
};

export default LoginPage;
