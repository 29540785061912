import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";

import { tokens } from "../../theme";

// import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import { styled } from "@mui/material/styles";
// import { makeStyles } from "@mui/system";

import { useEffect, useRef } from 'react';


// const useStyles = makeStyles((theme) => ({
//     root: {
//         margin: theme.spacing(2),
//         padding: theme.spacing(2),
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//     },
//     iframe: {
//         width: '100%',
//         height: 400,
//         border: 0,
//     },
// }));
// const useStyles = makeStyles((theme) => ({
//     root: {
//         margin: 0, // Reset the margin to 0
//         padding: 0, // Reset the padding to 0
//         height: '100vh', // Set the height to 100% of the viewport height
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//     },
//     iframe: {
//         width: '100%', // Set the width to 100%
//         height: '100%', // Set the height to 100%
//         border: 0,
//     },
// }));
const RootContainer = styled(Paper)({
    margin: 0,
    padding: 0,
    // height: '100vh',
    // height: '100%',
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    backgroundColor: 'blue'
});

const Iframe = styled('iframe')({
    width: '100%',
    height: '100%',
    border: 0,
    padding: '5px'
});


const DejavuTickmatch = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // const classes = useStyles();
    // const iframeSrc = 'http://ideatrade.serveftp.net:8080/petroleum2.html'
    const iframeSrc = 'https://a63b-147-50-252-213.ngrok-free.app/petroleum2.html'

    const iframeRef = useRef(null);
    useEffect(() => {
        // const handleResize = () => {
        //     const contentWidth = iframeRef.current.contentWindow.document.body.scrollWidth;
        //     const contentHeight = iframeRef.current.contentWindow.document.body.scrollHeight;

        //     // Set the dimensions of the iframe to match the content
        //     iframeRef.current.width = `${contentWidth}px`;
        //     iframeRef.current.height = `${contentHeight}px`;
        // };

        // // Attach the resize event listener
        // iframeRef.current.contentWindow.addEventListener('resize', handleResize);

        // // Remove the event listener when the component is unmounted
        // return () => {
        //     iframeRef.current.contentWindow.removeEventListener('resize', handleResize);
        // };

        const iframe = document.getElementById('myIframe');

        if (iframe) {
            // You can adjust the zoom level as needed (e.g., 1.2 for 120% zoom)
            const zoomLevel = 0.8;
            iframe.style.transform = `scale(${zoomLevel})`;
        }
    }, []);

    return (
        <Box sx={{

        }}>
            {/* <Typography variant="h5" gutterBottom>
                Embedded Content
            </Typography> */}
            {/* <Iframe
                title="Embedded Content"
                src={iframeSrc}
                frameBorder="0"
                allowFullScreen
            ></Iframe> */}
            <div style={{ position: 'relative', width: '125%', height: 0, paddingBottom: '75%'   }}>
                {/* 16:9 aspect ratio (adjust paddingBottom based on your desired aspect ratio) */}
                <iframe
                    id="myIframe"
                    title="Embedded Content"
                    src={iframeSrc}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        border: 0,
                        overflow: 'hidden',
                        transformOrigin: '0 0',
                    }}
                    allowFullScreen
                ></iframe>
            </div>
            {/* <iframe
                title="Dynamic Iframe"
                ref={iframeRef}
                src={iframeSrc}
                frameBorder="0"
                width="100%"
                height="500px"
            ></iframe> */}

        </Box>

    );
};

export default DejavuTickmatch;
