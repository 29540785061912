// // Preloader.jsx
// import React from 'react';
// import CircularProgress from '@mui/material/CircularProgress';

// const Preloader = () => {
//     return (
//         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
//             <CircularProgress />
//         </div>
//     );
// };

// export default Preloader;


// Preloader.jsx
import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Preloader = ({ open }) => {
    return (
        <Backdrop
            open={open}
            style={{ color: '#fff', zIndex: 1000 }}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default Preloader;
