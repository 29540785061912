import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

const useIsMobile = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return isMobile;
};


export { useIsMobile };